import React, { useEffect } from "react";

import { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { cities } from "../../cities";

import "react-datepicker/dist/react-datepicker.css";

import { supabase } from "../../supabaseClient";
import { baseUrl } from "../../helpers/helpers";
import { landcodes } from "../../landcodes";
import clsx from "clsx";
import DateInput from "../../components/DateInput";
import dayjs from "dayjs";
import { CustomSelect } from "../home/Home";
import { useTranslation } from "react-i18next";

export default function CreateAccount({ setLanguage }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [loginMethod, setLoginMethod] = useState("");

  const { t } = useTranslation();

  useEffect(() => {
    const retrieveAppleUser = () => {
      supabase.auth.getUser().then((user) => {
        const user_info = user.data.user.user_metadata;

        if (user_info.phone_number) {
          window.location.replace(searchParams.get("redirectUrl") ?? baseUrl);
        }

        setFormData({
          ...formData,
          email: user_info.email,
        });
      });
    };

    const retrieveGoogleUser = () => {
      supabase.auth.getUser().then((user) => {
        const user_info = user.data.user.user_metadata;

        if (user_info.phone_number) {
          window.location.replace(searchParams.get("redirectUrl") ?? baseUrl);
        }

        setFormData({
          ...formData,
          email: user_info.email,
          first_name: user_info.full_name.split(" ")[0],
          last_name: user_info.full_name.split(" ")[1],
        });
      });
    };

    console.log("searchParams", searchParams);

    if (searchParams.get("email")) {
      setLoginMethod("EMAIL");
      setFormData({ ...formData, email: searchParams.get("email") });
    } else if (searchParams.get("phone")) {
      setLoginMethod("PHONE_NUMBER");
      setFormData({
        ...formData,
        phone_number: "+" + searchParams.get("phone"),
      });
    } else if (searchParams.get("provider")) {
      const provider = searchParams.get("provider");
      if (provider === "google") {
        setLoginMethod("GOOGLE");

        retrieveGoogleUser();
      } else if (provider === "apple") {
        console.log("APPLE");
        setLoginMethod("APPLE");

        retrieveAppleUser();
      }
    }
  }, []);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    landcode: "+31",
    date_of_birth: "",
    location: "Amsterdam",
    gender: "Man",
    password: "",
    token: localStorage.getItem("token") ?? "AMSLIJST",
    agreement: false,
  });

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);

  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [citySearch, setCitySearch] = useState("");
  const [filteredCities, setFilteredCities] = useState([]);

  useEffect(() => {
    const filtered = cities.filter((city) =>
      city.toLowerCase().startsWith(citySearch.toLowerCase())
    );
    setFilteredCities(filtered);
  }, [citySearch]);

  const createAccount = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (formData.phone_number.length === 9) {
      const { data, error } = await supabase
        .from("User")
        .select("phone_number")
        .eq("phone_number", `${formData.landcode}${formData.phone_number}`);

      if (data.length > 0) {
        setError(true);
        setPhoneNumberExists(true);
        return;
      }
    }

    if (formData.password !== formData.confirm_password) {
      setError(true);
      setErrorMessage("Wachtwoorden komen niet overeen");
      return;
    }

    if (!formData.agreement) {
      setError(true);
      setErrorMessage("Je moet akkoord gaan met de algemene voorwaarden");
      return;
    }

    if (formData.phone_number.length !== 9) {
      setError(true);
      setErrorMessage("Telefoonnummer moet 9 cijfers lang zijn");
      return;
    }

    if (loginMethod === "EMAIL") {
      await supabase.auth.signUp({
        email: formData.email,
        password: formData.password,
        options: {
          data: {
            first_name: formData.first_name,
            last_name: formData.last_name,
            email: formData.email,
            phone_number: formData.landcode + formData.phone_number,
            date_of_birth: dayjs(formData.date_of_birth).format("DD-MM-YYYY"),
            gender: formData.gender,
            location: formData.location,
            token: formData.token,
          },
        },
      });

      // Probleem: email is nog niet geverifieerd dus kan je niet inserten in User

      navigate(
        `/choose-genres?provider=email&email=${formData.email}&redirectUrl=${
          searchParams.get("redirectUrl") ?? baseUrl
        }`
      );
    } else if (loginMethod === "PHONE_NUMBER") {
      const { data, error } = await supabase.auth.signUp({
        phone: formData.phone_number,
        password: formData.password,
        options: {
          data: {
            first_name: formData.first_name,
            last_name: formData.last_name,
            email: formData.email,
            phone_number: formData.landcode + formData.phone_number,
            date_of_birth: dayjs(formData.date_of_birth).format("DD-MM-YYYY"),
            gender: formData.gender,
            location: formData.location,
            token: formData.token,
          },
        },
      });

      if (!error) {
        await supabase.from("User").insert({
          email: formData.email,
          phone_number: formData.landcode + formData.phone_number,
          auth_user_id: data.user.id,
        });

        // TODO: Phone verification
        navigate(`/choose-genres?provider=phone&email=${formData.email}`);
      }
    } else if (loginMethod === "GOOGLE" || loginMethod === "APPLE") {
      const { data, error } = await supabase.auth.updateUser({
        password: formData.password,
        data: {
          first_name: formData.first_name,
          last_name: formData.last_name,
          email: formData.email,
          phone_number: formData.landcode + formData.phone_number,
          date_of_birth: dayjs(formData.date_of_birth).format("DD-MM-YYYY"),
          gender: formData.gender,
          location: formData.location,
          token: formData.token,
        },
      });

      if (!error) {
        await supabase.from("User").insert({
          email: formData.email,
          phone_number: formData.landcode + formData.phone_number,
          auth_user_id: data.user.id,
        });

        navigate(
          `/choose-genres?provider=google&email=${formData.email}&redirectUrl=${
            searchParams.get("redirectUrl") ?? baseUrl
          }`
        );
      }
    }
  };

  //   Validate the form data
  useEffect(() => {
    if (Object.values(formData).every((val) => val !== "")) {
      setSubmitButtonDisabled(false);
    } else {
      setSubmitButtonDisabled(true);
    }
  }, [formData]);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [phoneNumberExists, setPhoneNumberExists] = useState(false);

  return (
    <header className="login">
      <div className="z-[1000] absolute top-[30px] right-[20px] lg:top-[50px] lg:right-[50px]">
        <CustomSelect
          options={[
            { value: "nl", label: "🇳🇱 Nederlands" },
            { value: "en", label: "🇺🇸 English" },
          ]}
          onSelect={(value) => {
            setLanguage(value);
          }}
        />
      </div>
      <div className="row">
        <div className="logo-log flex items-center justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="371"
            height="154"
            viewBox="0 0 371 154"
            fill="none"
            className="lg:h-[40px] h-[25px] w-auto"
          >
            <path
              d="M122.554 154H1.6639V127.001L38.7884 89.5132C46.4837 81.4826 52.4458 75.0443 56.6747 70.1982C60.973 65.3522 63.9887 61.2331 65.7219 57.8409C67.455 54.4486 68.3216 50.918 68.3216 47.2488C68.3216 42.7489 66.8658 39.4952 63.954 37.4875C61.0423 35.4799 57.6106 34.4761 53.659 34.4761C48.8755 34.4761 44.0226 35.8606 39.1004 38.6298C34.2475 41.3297 28.736 45.345 22.5659 50.6757L6.0861e-05 24.2994C4.57563 20.2149 9.42851 16.3381 14.5587 12.6689C19.6889 8.93055 25.755 5.88447 32.757 3.53068C39.759 1.17689 48.2862 0 58.3386 0C68.6683 0 77.6114 1.79995 85.168 5.39987C92.794 8.99977 98.6868 13.9497 102.846 20.2495C107.006 26.4801 109.086 33.5761 109.086 41.5374C109.086 50.3295 107.457 58.187 104.198 65.1099C101.009 71.9636 96.191 78.8519 89.7436 85.7748C83.2962 92.6285 75.2543 100.417 65.6179 109.14L54.4909 119.109V120.147L122.554 119.109V154Z"
              fill="white"
            />
            <path
              d="M226.246 154H113.075V127.001L150.199 89.5132C157.895 81.4826 163.857 75.0443 168.086 70.1982C172.384 65.3522 175.4 61.2331 177.133 57.8409C178.866 54.4486 179.733 50.918 179.733 47.2488C179.733 42.7489 178.277 39.4952 175.365 37.4875C172.453 35.4799 169.022 34.4761 165.07 34.4761C160.286 34.4761 155.433 35.8606 150.511 38.6298C145.658 41.3297 140.147 45.345 133.977 50.6757L111.411 24.2994C115.987 20.2149 120.839 16.3381 125.97 12.6689C131.1 8.93055 137.166 5.88447 144.168 3.53068C151.17 1.17689 159.697 0 169.749 0C180.079 0 189.022 1.79995 196.579 5.39987C204.205 8.99977 210.098 13.9497 214.257 20.2495C218.417 26.4801 220.497 33.5761 220.497 41.5374C220.497 50.3295 218.867 58.187 215.609 65.1099C212.42 71.9636 207.602 78.8519 201.154 85.7748C194.707 92.6285 186.665 100.417 177.029 109.14L165.902 119.109V120.147H236.237L226.246 154Z"
              fill="white"
            />
            <path
              d="M371 154H317.133L261.602 47.0411H260.666C261.013 50.2949 261.325 54.2063 261.602 58.7755C261.88 63.3446 262.122 68.0175 262.33 72.7943C262.538 77.5019 262.642 81.7595 262.642 85.5671V154H226.246V2.18071H279.905L335.227 107.686H335.851C335.643 104.363 335.435 100.521 335.227 96.1591C335.019 91.7285 334.811 87.2632 334.603 82.7633C334.465 78.2634 334.395 74.2828 334.395 70.8213V2.18071H371V154Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
      <div className="login_form mt-[130px] mb-[100px] !min-h-[100vh]">
        <form
          autoComplete="off"
          className="form w-full"
          name="f1"
          onSubmit={createAccount}
          method="post"
        >
          <div className="lg">
            <div className="">
              <p className="flex protected">
                <span className="ml-1.5 text-[24px]">{t("register")}</span>
              </p>
              <div>
                <input
                  type="text"
                  name="name"
                  className="!py-[15px] outline-none !text-white !border-none !bg-[#130826]"
                  id="validationCustom01"
                  placeholder={t("first_name")}
                  value={formData.first_name}
                  onChange={(e) => {
                    setFormData({ ...formData, first_name: e.target.value });
                    setError(false);
                  }}
                />
                <input
                  type="text"
                  name="name"
                  className="mt-1 !py-[15px] outline-none !text-white !border-none !bg-[#130826]"
                  id="validationCustom01"
                  placeholder={t("last_name")}
                  value={formData.last_name}
                  onChange={(e) => {
                    setFormData({ ...formData, last_name: e.target.value });
                    setError(false);
                  }}
                />
                <p className="text-[#cccccc] mt-2 text-[14px]">
                  {t("name_check")}
                </p>
              </div>
              <div className="mt-[24px]">
                <DateInput
                  className="mt-1 w-full rounded-[4px] !p-[15px] outline-none !text-white !border-none !bg-[#130826]"
                  dob={formData.date_of_birth}
                  setDob={(d) => {
                    setFormData({ ...formData, date_of_birth: d });
                    setError(false);
                  }}
                />
                <p className="text-[#cccccc] mt-2 text-[14px]">
                  {t("birth_date_check")}
                </p>
              </div>
              <div className="mt-[24px]">
                <div className="relative">
                  <input
                    type="text"
                    value={citySearch}
                    onChange={(e) => setCitySearch(e.target.value)}
                    placeholder={t("search_city")}
                    className="text-[16px] w-full mb-1 rounded-[4px] !p-[15px] outline-none !text-white !border-none !bg-[#130826]"
                    autoComplete="off"
                  />
                  {citySearch && filteredCities.length > 0 && (
                    <ul className="absolute z-10 w-full bg-[#130826] border border-gray-700 rounded-b-[4px] max-h-[200px] overflow-y-auto">
                      {filteredCities.map((city, index) => (
                        <li
                          key={index}
                          className="px-4 py-2 cursor-pointer text-white"
                          onClick={() => {
                            setFilteredCities([]);
                            setFormData({ ...formData, location: city });
                            setCitySearch(city);
                          }}
                        >
                          {city}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <p className="text-[#cccccc] mt-2 text-[14px]">
                  {t("city_info")}
                </p>
              </div>
              <div className="mt-[24px]">
                <select
                  defaultValue={"Man"}
                  onChange={(e) => {
                    setFormData({ ...formData, gender: e.target.value });
                    setError(false);
                  }}
                  className="text-[16px] w-full mb-1 rounded-[4px] !p-[15px] outline-none !text-white !border-none !bg-[#130826]"
                >
                  <option value="Man">Man</option>
                  <option value="Vrouw">Vrouw</option>
                  <option value="Anders">Anders</option>
                </select>
                <p className="text-[#cccccc] mt-2 text-[14px]">
                  {t("gender_info")}
                </p>
              </div>
              <div className="mt-[24px]">
                {(loginMethod === "EMAIL" ||
                  loginMethod === "GOOGLE" ||
                  loginMethod === "APPLE") && (
                  <>
                    <div>
                      <select
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            landcode: e.target.value,
                          });
                          setError(false);
                        }}
                        value={formData.landcode}
                        className="text-[16px] w-full mb-1 rounded-[4px] !p-[15px] outline-none !text-white !border-none !bg-[#130826]"
                      >
                        {landcodes.map((code) => {
                          return (
                            <option value={code.dial_code}>
                              {code.name} ({code.dial_code})
                            </option>
                          );
                        })}
                      </select>
                      <input
                        type="text"
                        name="name"
                        className={clsx(
                          "!py-[15px] outline-none !text-white !border-none !bg-[#130826]",
                          error || phoneNumberExists ? "error-border" : ""
                        )}
                        id="validationCustom01"
                        placeholder={t("phone_number")}
                        value={formData.phone_number}
                        onChange={(e) => {
                          setPhoneNumberExists(false);
                          setFormData({
                            ...formData,
                            phone_number: e.target.value,
                          });
                          setError(false);
                        }}
                      />
                      {phoneNumberExists && (
                        <p className="text-red-500 font-bold mt-2 text-[14px]">
                          {t("phone_number_exists")}
                        </p>
                      )}
                    </div>
                    <p className="text-[#cccccc] mt-2 text-[14px]">
                      {t("phone_number_info")}
                    </p>
                    <input
                      type="email"
                      name="name"
                      disabled={true}
                      className="!py-[15px] mt-8 outline-none !text-neutral-400 !cursor-not-allowed !border-none !bg-[#3e3c42]"
                      id="validationCustom01"
                      placeholder="E-mail"
                      value={formData.email}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          email: e.target.value,
                        });
                        setError(false);
                      }}
                    />
                  </>
                )}
                {loginMethod === "PHONE_NUMBER" && (
                  <>
                    <input
                      type="email"
                      name="name"
                      className="!py-[15px] outline-none !text-white !border-none !bg-[#130826]"
                      id="validationCustom01"
                      placeholder="E-mail"
                      value={formData.email}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          email: e.target.value,
                        });
                        setError(false);
                      }}
                    />
                    <p className="text-[#cccccc] mt-2 text-[14px]">
                      {t("email_info")}
                    </p>
                  </>
                )}
              </div>
              <div className="mt-[24px]">
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="name"
                    className="w-full !py-[15px] outline-none !text-white !border-none !bg-[#130826] pr-10"
                    id="validationCustom01"
                    placeholder={t("password")}
                    value={formData.password}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        password: e.target.value,
                      });
                      setError(false);
                    }}
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 pr-3 flex items-center"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 text-white"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 text-white"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                    )}
                  </button>
                </div>
                <div className="relative">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    name="name"
                    className="w-full !py-[15px] mt-1 outline-none !text-white !border-none !bg-[#130826] pr-10"
                    id="validationCustom01"
                    placeholder={t("repeat_password")}
                    value={formData.confirm_password}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        confirm_password: e.target.value,
                      });
                      setError(false);
                    }}
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 pr-3 flex items-center"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 text-white"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 text-white"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                    )}
                  </button>
                </div>
                <div className="flex items-center justify-start gap-3 mt-3">
                  <input
                    type="checkbox"
                    checked={formData.agreement}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        agreement: e.target.checked,
                      });
                      setError(false);
                    }}
                    className="h-8 w-8 bg-transparent rounded-[4px]"
                  />

                  <p className="text-[#cccccc] text-[14px]">
                    {t("I_agree")}{" "}
                    <span className="">
                      <Link
                        to="https://beta.22night.com/Terms_PDF/Algemene_Voorwaarden_22Night_bv.pdf"
                        target="_blank"
                        className="text-purple-400 !underline text-[14px] visited:text-purple-400"
                      >
                        {t("privacy_policy")}
                      </Link>
                    </span>
                    .
                  </p>
                </div>
              </div>

              {error && (
                <p className="text-red-500 font-semibold text-[16px] mt-4">
                  {errorMessage}
                </p>
              )}

              <div>
                <button
                  disabled={submitButtonDisabled}
                  type="submit"
                  className="mt-[24px] hover:text-white !disabled:cursor-not-allowed disabled:bg-[#56466B] text-[16px] font-semibold btn enabled:bg-gradient-to-r shadow-md enabled:to-[#7721E5] enabled:from-[#6A1BD1] text-white w-full px-[20px] py-[14px] rounded-[8px]"
                >
                  {t("agree_and_continue")}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </header>
  );
}
