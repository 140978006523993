import React from "react";
import { Link } from "react-router-dom";
import ShoppingBag from "../assets/shopping-bag.svg";
import FeedbackIcon from "../assets/feedback-icon.svg";
import { useSelector } from "react-redux";
import { selectUser } from "../slices/userReducer";
import Icon from "./Icon";
import { adminUserIds } from "../helpers/helpers";

export default function MobileMenu({ path }) {
  const user = useSelector(selectUser);

  return (
    <div className="bottom-navbar text-white fixed bottom-0 w-full gap-[24px] py-[16px] flex items-center justify-center z-[100000]">
      <Link
        to="/"
        className={`text-[12px] hover:text-[#C9A6F5] leading-[14px] font-semibold flex flex-col gap-1 items-center justify-center ${
          path === "/" && "text-[#C9A6F5]"
        }`}
      >
        <Icon
          icon="ph:confetti"
          className={`${
            path.includes("/profile") && "fill-[#C9A6F5"
          } h-[24px] w-[24px]`}
        />
        Events
      </Link>
      <Link
        to="/lifestyle"
        className={`text-[12px] hover:text-[#C9A6F5] leading-[14px] font-semibold flex flex-col gap-1 items-center justify-center ${
          path === "/lifestyle" && "text-[#C9A6F5]"
        }`}
      >
        <img
          src={ShoppingBag}
          alt="Dj Set"
          className={`${path === "/lifestyle" && "fill-[#C9A6F5"}`}
        />
        Lifestyle
      </Link>

      {adminUserIds.includes(user.id) ? (
        <Link
          to="/admin/events"
          className="text-[12px] leading-[14px] font-semibold flex flex-col gap-1 items-center justify-center hover:text-[#C9A6F5] text-white"
        >
          <Icon
            icon="heroicons:cog-6-tooth"
            className="h-[22px] w-[22px]"
            alt="Feedback icon"
          />
          <p className="text-[12px]">Admin</p>
        </Link>
      ) : (
        <Link
          to="https://wa.me/31617476764"
          target="_blank"
          className="text-[12px] leading-[14px] font-semibold flex flex-col gap-1 items-center justify-center hover:text-[#C9A6F5] text-white"
        >
          <img src={FeedbackIcon} alt="Dj Set" />
          Feedback
        </Link>
      )}

      {user.id ? (
        <Link
          to="/profile"
          className={`text-[12px] hover:text-[#C9A6F5] leading-[14px] font-semibold flex flex-col gap-1 items-center justify-center ${
            path.includes("/profile") && "text-[#C9A6F5]"
          }`}
        >
          <Icon
            icon="heroicons:user-circle"
            className={`${
              path.includes("/profile") && "fill-[#C9A6F5"
            } h-[24px] w-[24px]`}
          />
          {user.first_name}
        </Link>
      ) : (
        <Link
          to="/email-login"
          className={`text-[12px] hover:text-[#C9A6F5] leading-[14px] font-semibold flex flex-col gap-1 items-center justify-center`}
        >
          <Icon icon="heroicons:user-circle" className="h-[24px] w-[24px]" />
          Login
        </Link>
      )}
    </div>
  );
}
