import { InfoSvg } from '../utils/svg';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/nl';


// import utc from 'dayjs/plugin/utc';
import { useNavigate } from 'react-router-dom';
dayjs.extend(utc);
dayjs.extend(timezone);

const EventCardPreloader = () => {




  return (
    <div className='hover:cursor-pointer event-content text-white doubles'>
      <a>
        <h5 className="Charlee">
          Some event
        </h5>
        <h6 className="text-light-gray">
          event club, event city
        </h6>


        <div className="tooltipss">
          <span>7 october</span>
          <span>21+</span>
          <div className="tool">
            <span className="gen">
              Preloader
            </span>{' '}
            <span className="tooltips">
              {' '}
              <InfoSvg />

              <span className="tooltiptext tooltipText wa Vriendenticket">
                We zetten jouw naam op de gastenlijst, zodat je gratis of
                goedkoper naar binnen kan.
              </span>
            </span>
          </div>
        </div>

      </a>
    </div>
  );
};

export default EventCardPreloader;
