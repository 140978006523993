import React, { useState } from "react";
import { Input } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

export default function EventSignupForm({
  eFormData,
  formErrors,
  setEFormData,
  setFormErrors,
  event,
  refForm,
  soldOutStatus,
  setBtnDisabled,
  handleSubmit,
  btnDisabled,
}) {
  const { t } = useTranslation();
  const [dobPlaceHolder, setDobPlaceHolder] = useState(t("date_of_birth"));

  const focusDob = () => {
    setDobPlaceHolder("dd-mm-jjjj");
  };

  const blurDob = () => {
    setDobPlaceHolder(t("date_of_birth"));
  };

  const validateName = (name) => {
    return /^[A-Za-z]+(\s[A-Za-z]+)*$/.test(name);
  };

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const onChange = (e) => {
    setEFormData({ ...eFormData, [e.target.name]: e.target.value.trim() });

    switch (e.target.name) {
      case "Firstname":
        if (formErrors.hasOwnProperty("Firstname") && formErrors.Firstname) {
          setFormErrors({
            ...formErrors,
            Firstname: !validateName(eFormData.Firstname),
          });
        }
        break;

      case "Lastname":
        if (formErrors.hasOwnProperty("Lastname") && formErrors.Lastname) {
          setFormErrors({
            ...formErrors,
            Lastname: !validateName(eFormData.Lastname),
          });
        }
        break;
      case "Email":
        if (formErrors.hasOwnProperty("Email") && formErrors.Email) {
          setFormErrors({
            ...formErrors,
            Email: !validateEmail(eFormData.Email),
          });
        }
        break;
      case "gender":
        if (formErrors.hasOwnProperty("gender") && formErrors.gender) {
          setFormErrors({
            ...formErrors,
            gender: ["Man", "Vrouw", "Onzijdig"].includes(eFormData.gender),
          });
        }
        break;
      case "agreement":
        setFormErrors({ ...formErrors, agreement: !e.target.value });
        break;
      case "dob":
        setFormErrors({ ...formErrors, dob: !validateDob(eFormData.dob) });

      default:
        break;
    }
  };

  const changeDob = (e) => {
    const value = e.target.value;
    let formattedValue = value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    let placeholder = "dd-mm-jjjj";
    let cursorPosition = formattedValue.length;
    const key = e.nativeEvent.inputType;

    const padWithZero = (number) => {
      return number.toString().padStart(2, "0");
    };

    // Format day (dd)
    if (formattedValue.length >= 2) {
      let day = parseInt(formattedValue.slice(0, 2), 10);

      if (day < 1) {
        day = 1;
      } else if (day > 31) {
        day = 31;
      }

      formattedValue = padWithZero(day) + "-" + formattedValue.slice(2);
      placeholder = formattedValue + placeholder.slice(3);
      cursorPosition = 3;
    }

    // Format month (mm)
    if (formattedValue.length >= 5) {
      let month = parseInt(formattedValue.slice(3, 5), 10);

      if (month < 1) {
        month = 1;
      } else if (month > 12) {
        month = 12;
      }

      formattedValue =
        formattedValue.slice(0, 3) +
        padWithZero(month) +
        "-" +
        formattedValue.slice(5);
      placeholder = placeholder.slice(0, 3) + "-mm-" + placeholder.slice(5);
      cursorPosition = 6;
    }

    // Format year (jjjj)
    if (formattedValue.length >= 10) {
      let year = parseInt(formattedValue.slice(6, 10), 10);

      const minYear = 1930;
      const maxYear = 2023;

      if (year < minYear) {
        year = minYear;
      } else if (year > maxYear) {
        year = maxYear;
      }

      formattedValue =
        formattedValue.slice(0, 6) + year.toString().padStart(4, "0");

      const age = extractAge(formattedValue);
      if (age < +event.minimum_age) {
        setBtnDisabled(true);
      } else {
        setBtnDisabled(false);
      }
    }

    if (
      key === "deleteContentBackward" &&
      (formattedValue.length === 6 || formattedValue.length === 3)
    ) {
      formattedValue =
        formattedValue.slice(0, cursorPosition - 1) +
        formattedValue.slice(cursorPosition);
      cursorPosition -= 1;
    }

    setEFormData({ ...eFormData, dob: formattedValue });
    setDobPlaceHolder(placeholder);
  };

  const extractAge = (date) => {
    const [day, month, year] = date.split("-");
    const birthDate = new Date(`${year}-${month}-${day}`);
    const currentDate = new Date();

    let age = currentDate.getFullYear() - birthDate.getFullYear();

    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    // console.log('AGE', age)
    return age;
  };

  const validateDob = (dob) => {
    if (dob.length < 10) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <section className="event-form-sec w-full lg:max-w-[50%] lg:w-[700px] relative lg:mt-20 mt-12 ">
      <div>
        <div className="event-information-form " ref={refForm}>
          <h3 className="text-white form-heading">{t("your_details")}</h3>
          <form>
            <div className="first-name event-sec-forms">
              <div className="col-md-6">
                <Input
                  type="text"
                  placeholder={t("first_name")}
                  name="Firstname"
                  value={eFormData.Firstname}
                  className={formErrors.Firstname ? "sin error-field" : "sin"}
                  onInput={onChange}
                  // autoComplete='off'
                />
              </div>
              <div className="col-md-6">
                <Input
                  type="text"
                  placeholder={t("last_name")}
                  name="Lastname"
                  value={eFormData.Lastname}
                  className={formErrors.Lastname ? "sin error-field" : "sin"}
                  // autoComplete='off'
                  onInput={onChange}
                />
              </div>
            </div>
            <div className="email event-sec-form">
              <Input
                type="email"
                name="Email"
                id="email"
                value={eFormData.Email}
                placeholder={t("email")}
                className={formErrors.Email ? "sin error-field" : "sin"}
                // autoComplete='off'
                onInput={onChange}
              />
            </div>
            <div
              className={
                formErrors.dob
                  ? "date event-sec-form error-field"
                  : "date event-sec-form"
              }
            >
              <Input
                type="text"
                maxLength="10"
                id="date"
                name="dob"
                value={eFormData.dob}
                inputMode="numeric"
                className={formErrors.dob ? "sin error-field" : "sin"}
                placeholder={dobPlaceHolder}
                onFocus={focusDob}
                onBlur={blurDob}
                onChange={changeDob}
                autoComplete="off"
              />
              {/* <Flatpickr {...flatpickrProps} /> */}
            </div>

            <div className="geslacht event-sec-form sin">
              <select
                id="geslacht"
                className={
                  formErrors.gender
                    ? "cx-ui-select error-field"
                    : "cx-ui-select"
                }
                name="gender"
                size="1"
                data-filter="false"
                data-placeholder=""
                onChange={onChange}
              >
                <option value="gender" disabled selected>
                  {t("gender")}
                </option>
                <option value="Man">{t("male")}</option>
                <option value="Vrouw">{t("female")}</option>
                <option value="Onzijdig">{t("other")}</option>
              </select>
            </div>

            <div
              className={
                formErrors.agreement ? "checkbox error-field" : "checkbox"
              }
            >
              <div className="check">
                <Input
                  type="checkbox"
                  name="agreement"
                  id="agreement"
                  checked={eFormData.agreement}
                  onChange={(e) => {
                    onChange(e);
                    setEFormData({
                      ...eFormData,
                      agreement: e.target.checked,
                    });
                  }}
                />
              </div>
              <div className="texts">
                <label className="text-white" htmlFor="agreement">
                  {t("i_agree_to_the")}{" "}
                  <a
                    href="https://beta.22night.com/Terms_PDF/Algemene_Voorwaarden_22Night_bv.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("terms_and_conditions")}.
                  </a>{" "}
                </label>
              </div>
            </div>

            <div className="max-lg:hidden">
              <hr className="w-full max-lg:hidden !my-[24px]  !border-gray-800" />

              <div className="flex justify-between w-full items-center">
                <h3 className="!pb-0 text-[18px] font-semibold text-white">
                  Totaal
                </h3>
                <h3 className="!pb-0 text-[22px] font-semibold text-white">
                  €{event.our_price.toFixed(2)}
                </h3>
              </div>
              <div className="flex justify-between w-full items-center">
                <h3 className="text-[16px] font-semibold text-neutral-300">
                  Reguliere prijs
                </h3>
                <h3 className="text-[16px] line-through font-semibold text-neutral-300">
                  €{event.original_price.toFixed(2)}
                </h3>
              </div>
              <div className="price-button w-full ">
                <button
                  className="mt-[16px] !w-full button openPopupButtons !text-[17px] !py-[24px] !px-[30px] premium-button"
                  type="button"
                  onClick={handleSubmit}
                  disabled={
                    soldOutStatus === true || btnDisabled === true
                      ? "disabled"
                      : ""
                  }
                >
                  Claim ticket
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div id="popups" className="popups">
        <div className="popup-contents">
          <img
            src="assets/image svg/Illustration.svg"
            className="Illustration"
          />
          <p className="Hier">{t("here_is_your_community_ticket")}</p>
          <p className="Je">
            Je ontvangt een e-mail van de organisatie zodra de betaling is
            afgerond.
          </p>
          <a href="index.html" className="Terug">
            {t("back_to_home")}
          </a>
        </div>
      </div>
    </section>
  );
}
