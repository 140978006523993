const EmptySearchResult = () => {
  return (
    <div className="search-information-service serach-info">
      <div className="row">
        <div className="search-information-content">
          <h5>No results found</h5>
        </div>
      </div>
    </div>
  );
};

export default EmptySearchResult;
