import { TrendingCheckSvg, TrendingStarSvg } from "../utils/svg";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs";
import "dayjs/locale/nl";
import { adjustTime } from "../helpers/helpers";

import { useNavigate } from "react-router-dom";
dayjs.extend(utc);
dayjs.extend(timezone);

const SearchItem = ({ searchItem, isEven, formattedCurrentDate }) => {
  // TODO: CHECK THIS COMPONENT

  const navigate = useNavigate();
  const formattedDate = dayjs
    .unix(searchItem.datetime)
    .utc()
    .locale("nl")
    .format("D MMMM HH:mm");

  const handleItemClick = () => {
    const registerFrom = adjustTime(searchItem.signup_from);

    if (formattedCurrentDate < registerFrom) return;
    navigate(`/single-event?eventId=${searchItem.id}`, {
      state: searchItem.id,
    });
  };

  const eventName = searchItem.name.split(/[\s-]+/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");

  return (
    <a onClick={handleItemClick}>
      <div className="search-information-service serach-info">
        <div className="flex">
          <div className="search-information-image">
            {isEven ? <TrendingCheckSvg /> : <TrendingStarSvg />}
          </div>

          <div className="search-information-content max-w-[90%]">
            <h5 className="truncate overflow-ellipsis">{eventName}</h5>
            <p>
              {formattedDate} &bull; {searchItem.category}
            </p>
          </div>
        </div>
      </div>
    </a>
  );
};

export default SearchItem;
