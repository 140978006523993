import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: process.env.NODE_ENV === "production" ? "https://api.22night.com" : "http://localhost:8000",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  withCredentials: true,
});
