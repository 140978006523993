import React from 'react'
export default function NoEvents() {
  return (
    <div className="custom-container">
      <div className="tab">
        <div className="tab-header">
          <div className="row justify-content-between text-white">
            <div className="days">
              {/* <h3 className="donderdag">{dayName}</h3> */}
            </div>
            <div className="date">
              {/* <h5 className="date">{date}</h5> */}
            </div>
          </div>
        </div>
        <div className="tab-body">
          <div className="event-box">
            <div className="row">
              <div className={'event-content text-white'}
              >
                <h5 className='Charlee'>Geen events gevonden</h5>
                <p className='text-light-gray'>Op dit moment bieden wij geen events aan
                  binnen deze geselecteerde zoekopdracht.</p>
                <a>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
