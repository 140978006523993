import React, { useEffect, useState } from "react";
import { Table } from "flowbite-react";
import Icon from "../components/Icon";
import { supabase } from "../supabaseClient";
import dayjs from "dayjs";
import EditDealModal from "../components/EditDealModal";

export default function ManageDeals() {
  const [deals, setDeals] = useState([]);

  const [currentDeal, setCurrentDeal] = useState();

  const [showEditDealModal, setShowEditDealModal] = useState({
    show: false,
    create: true,
  });

  useEffect(() => {
    supabase
      .from("Deals")
      .select("*")
      .then((res) => {
        setDeals(res.data);
      });
  }, []);

  return (
    <div className="w-full text-white text-3xl mt-12 overflow-x-auto p-5">
      <EditDealModal
        show={showEditDealModal.show}
        setShow={(show) => {
          setShowEditDealModal({ ...showEditDealModal, show: show });
        }}
        create={showEditDealModal.create}
        currentDeal={currentDeal}
      />

      <div className="flex justify-between items-center w-full">
        <h1 className="text-white !text-5xl font-semibold !text-start w-fit">
          Manage Deals
        </h1>
        <button
          onClick={() => {
            setShowEditDealModal({
              show: true,
              create: true,
            });
          }}
          className="bg-white py-5 px-12 rounded-lg shadow-md font-semibold text-black !text-[20px] flex items-center justify-center gap-2"
        >
          <Icon icon="heroicons:plus" className="h-9 w-9" />
        </button>
      </div>
      <Table className="mt-8">
        <Table.Head>
          <Table.HeadCell className="text-3xl capitalize">Name</Table.HeadCell>
          <Table.HeadCell className="text-3xl capitalize">
            Gepubliceerd
          </Table.HeadCell>
          <Table.HeadCell className="text-3xl capitalize">
            <span className="sr-only">Edit</span>
          </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {deals.map((deal) => {
            return (
              <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                <Table.Cell className="p-20 text-2xl whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {deal.name}
                </Table.Cell>

                <Table.Cell className="text-2xl">
                  {dayjs(deal.created_at).format("DD MMMM YYYY HH:mm")}
                </Table.Cell>
                <Table.Cell>
                  <button
                    onClick={() => {
                      setCurrentDeal(deal);
                      setShowEditDealModal({
                        show: true,
                        create: false,
                      });
                    }}
                    className="font-medium text-cyan-600 hover:underline dark:text-cyan-500 text-2xl"
                  >
                    Edit
                  </button>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
}
