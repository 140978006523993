import { useEffect, useState } from "react";
import Icon from "./Icon";

export default function SuccessPopup({ show, message, error = false }) {
  const [shouldRender, setShouldRender] = useState(show);
  const [isVisible, setIsVisible] = useState(show);

  useEffect(() => {
    if (show) {
      setShouldRender(true);
      setTimeout(() => setIsVisible(true), 10);
    } else {
      setIsVisible(false);
      const timeoutId = setTimeout(() => setShouldRender(false), 300); // Match the animation duration
      return () => clearTimeout(timeoutId);
    }
  }, [show]);

  return (
    <>
      {shouldRender && (
        <div
          style={{
            pointerEvents: "none",
          }}
          className={`fixed bottom-[70px] left-1/2 -translate-x-1/2 px-8 py-3 flex justify-start z-[1000] items-center gap-3 transition-opacity duration-300 ${
            isVisible ? "opacity-100" : "opacity-0"
          }`}
        >
          <div
            className={`${
              error ? "bg-red-100" : "bg-green-100"
            } px-8 py-3 flex justify-start relative z-[1000] items-center gap-3 rounded-xl shadow-xl transition-transform duration-300 ${
              isVisible ? "translate-y-0" : "translate-y-full"
            }`}
          >
            {error ? (
              <Icon icon="mdi:close-circle" className="text-red-500 text-4xl" />
            ) : (
              <Icon
                icon="mdi:check-circle"
                className="text-green-500 text-4xl"
              />
            )}
            <h4
              className={`text-lg font-semibold -mt-1 ${
                error ? "text-red-800" : "text-green-800"
              }`}
            >
              {message}
            </h4>
          </div>
        </div>
      )}
    </>
  );
}
