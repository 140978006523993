import React from "react";
import { Link } from "react-router-dom";
import ShoppingBag from "../assets/shopping-bag.svg";
import Icon from "./Icon";
import FeedbackIcon from "../assets/feedback-icon.svg";

export default function AdminMobileMenu({ path }) {
  return (
    <div className="bottom-navbar text-white fixed bottom-0 w-full gap-[24px] py-[16px] flex items-center justify-center z-[100000]">
      <Link
        to="/"
        className={`text-[12px] hover:text-[#C9A6F5] leading-[14px] font-semibold flex flex-col gap-1 items-center justify-center ${
          path === "/" && "text-[#C9A6F5]"
        }`}
      >
        <Icon
          icon="heroicons:arrow-left-end-on-rectangle"
          className={`${
            path.includes("/") && "fill-[#C9A6F5"
          } h-[24px] w-[24px]`}
        />
        Back
      </Link>
      <Link
        to="/admin/events"
        className={`text-[12px] hover:text-[#C9A6F5] leading-[14px] font-semibold flex flex-col gap-1 items-center justify-center ${
          path === "/admin/events" && "text-[#C9A6F5]"
        }`}
      >
        <Icon
          icon="ph:confetti"
          className={`${
            path.includes("/admin/events") && "fill-[#C9A6F5"
          } h-[24px] w-[24px]`}
        />
        Events
      </Link>
      <Link
        to="/admin/lifestyle"
        className={`text-[12px] hover:text-[#C9A6F5] leading-[14px] font-semibold flex flex-col gap-1 items-center justify-center ${
          path === "/admin/lifestyle" && "text-[#C9A6F5]"
        }`}
      >
        <img
          src={ShoppingBag}
          alt="Dj Set"
          className={`${path === "/admin/lifestyle" && "fill-[#C9A6F5"}`}
        />
        Lifestyle
      </Link>

      <Link
        to="/admin/venues"
        className="text-[12px] leading-[14px] font-semibold flex flex-col gap-1 items-center justify-center hover:text-[#C9A6F5] text-white"
      >
        <img
          src={FeedbackIcon}
          className="h-[20px] w-[20px]"
          alt="Feedback icon"
        />
        <p className="text-[12px]">Venues</p>
      </Link>
    </div>
  );
}
