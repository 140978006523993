import React, { useEffect, useState } from "react";
import { Table } from "flowbite-react";
import Icon from "../components/Icon";
import { supabase } from "../supabaseClient";
import EditVenueModal from "./EditVenueModal";

export default function ManageVenues() {
  const [venues, setVenues] = useState([]);

  const [currentVenue, setCurrentVenue] = useState();

  const [showEditVenueModal, setShowEditVenueModal] = useState({
    show: false,
    create: true,
  });

  useEffect(() => {
    supabase
      .from("Venue")
      .select("*")
      .then((res) => {
        setVenues(res.data);
      });
  }, []);

  const removeVenue = (id) => {
    if (window.confirm("Are you sure you want to delete this venue?")) {
      supabase.from("Venue").delete().eq("id", id).then((res) => {
        setVenues(venues.filter((venue) => venue.id !== id));
      });
    }
  };

  return (
    <div className="w-full text-white text-3xl mt-12 overflow-x-auto p-5">
      <EditVenueModal
        show={showEditVenueModal.show}
        setShow={(show) => {
          setShowEditVenueModal({ ...showEditVenueModal, show: show });
        }}
        create={showEditVenueModal.create}
        currentVenue={currentVenue}
      />

      <div className="flex justify-between items-center w-full">
        <h1 className="text-white !text-5xl font-semibold !text-start w-fit">
          Manage venues
        </h1>
        <button
          onClick={() => {
            setShowEditVenueModal({
              show: true,
              create: true,
            });
          }}
          className="bg-white py-5 px-12 rounded-lg shadow-md font-semibold text-black !text-[20px] flex items-center justify-center gap-2"
        >
          <Icon icon="heroicons:plus" className="h-9 w-9" />
        </button>
      </div>
      <Table className="mt-8 mb-[50px]">
        <Table.Head>
          <Table.HeadCell className="text-3xl capitalize">Name</Table.HeadCell>
          <Table.HeadCell className="text-3xl capitalize">
            Address
          </Table.HeadCell>
          <Table.HeadCell className="text-3xl capitalize">City</Table.HeadCell>
          <Table.HeadCell className="text-3xl capitalize">
            <span className="sr-only">Actions</span>
          </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {venues.map((venue) => {
            return (
              <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                <Table.Cell className="p-12 text-2xl whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {venue.name}
                </Table.Cell>
                <Table.Cell className="text-2xl">
                  {venue.street_address}
                </Table.Cell>
                <Table.Cell className="text-2xl">{venue.city}</Table.Cell>
                <Table.Cell>
                  <button
                    onClick={() => {
                      removeVenue(venue.id);
                    }}
                    className="font-medium text-red-600 hover:underline dark:text-red-500 text-2xl"
                  >
                    <Icon icon="heroicons:trash" className="h-9 w-9" />
                  </button>
                  <button
                    onClick={() => {
                      setCurrentVenue(venue);
                      setShowEditVenueModal({
                        show: true,
                        create: false,
                      });
                    }}
                    className="font-medium text-cyan-600 hover:underline dark:text-cyan-500 text-2xl"
                  >
                    <Icon icon="heroicons:pencil-square" className="h-9 w-9" />
                  </button>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
}
