import { Link, useNavigate } from "react-router-dom";
import { LogoSvg } from "../utils/svg";
import ArrowImg from "../assets/image svg/arrow-left.svg";
import popupImg from "../assets/image svg/Illustration.svg";
import "./thanks/thanksPage.css";
import { useTranslation } from "react-i18next";

const VerifyEmailPage = () => {
  const { t } = useTranslation();

  return (
    <div>
      <section class="hero-section-single thanksPage min-h-screen">
        <div class="container-single">
          <div className="row">
            <div className="arrow"></div>
            <div className="logo-Img">
              <a href="/">
                <LogoSvg />
              </a>
            </div>
          </div>
        </div>
        <div class="popup-content-box">
          <div class="popup-content">
            <img src={popupImg} class="Illustration" />
            <p class="Hier">{t("verify_email")}</p>
            <p class="Je">{t("verify_email_message")}</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default VerifyEmailPage;
