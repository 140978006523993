import { useState } from "react";
import { Button } from "@chakra-ui/react";
import clsx from "clsx";
import { useLocation, useNavigate } from "react-router-dom";
import { LogoSvg } from "../../utils/svg";
import ErrorSvg from "../../assets/image svg/error.svg";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Icon from "../../components/Icon";
import AppleAuth from "../../components/AppleAuth";
import GoogleAuth from "../../components/GoogleAuth";
import { landcodes } from "../../landcodes";
import { supabase } from "../../supabaseClient";

const PhoneLogin = () => {
  const [error, setError] = useState(false);

  const [landCode, setLandCode] = useState("+31");

  const [phoneNumber, setPhoneNumber] = useState("");

  const [password, setPassword] = useState("");

  const [phoneExists, setPhoneExists] = useState(false);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const nextStep = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const getUser = async () => {
      if (phoneNumber.length < 9 || phoneNumber.length > 10) {
        setError(true);
        return;
      }

      const possibleUser = await supabase
        .from("User")
        .select("phone_number")
        .eq("phone_number", landCode + phoneNumber)
        .limit(1)
        .single();

      return possibleUser;
    };

    getUser().then((user) => {
      if (!user.data) {
        navigate(
          `/create-account?phone=${landCode.replace("+", "") + phoneNumber}`
        );
      } else {
        setPhoneExists(true);
      }
    });
  };

  const signIn = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { data, error } = await supabase.auth.signInWithPassword({
      phone: landCode + phoneNumber,
      password: password,
    });

    console.log("data", data);

    if (error) {
      setError(true);
    } else {
      navigate("/");
    }
  };
  return (
    <>
      <header className="login">
        <div className="row">
          <div className="logo-log">
            <a href="/">
              <LogoSvg />
            </a>
          </div>
        </div>
        <div className="login_form">
          <form
            className="form w-full"
            name="f1"
            onSubmit={phoneExists ? signIn : nextStep}
            method="post"
          >
            <div className="lg">
              <div className="">
                <p className="flex protected">
                  <span className="ml-1.5 text-[24px]">Welkom bij 22NIGHT</span>
                </p>
                <div>
                  <select
                    onChange={(e) => {
                      setLandCode(e.target.value);
                      setError(false);
                    }}
                    value={landCode}
                    className="text-[16px] w-full mb-1 rounded-[4px] !p-[15px] outline-none !text-white !border-none !bg-[#130826]"
                  >
                    {landcodes.map((code) => {
                      return (
                        <option value={code.dial_code}>
                          {code.name} ({code.dial_code})
                        </option>
                      );
                    })}
                  </select>
                  <input
                    type="text"
                    name="name"
                    className={clsx(
                      "!py-[15px] outline-none !text-white !border-none !bg-[#130826]",
                      error ? (phoneNumber ? "" : "error-border") : ""
                    )}
                    id="validationCustom01"
                    placeholder="Telefoonnummer"
                    value={phoneNumber}
                    onChange={(e) => {
                      setPhoneNumber(e.target.value);
                      setError(false);
                    }}
                  />
                </div>
                {phoneExists && (
                  <div className="mt-1">
                    <input
                      type="password"
                      name="pass"
                      className={clsx(
                        "!py-[15px] outline-none !text-white !border-none !bg-[#130826]",
                        error ? (password ? "" : "error-border") : ""
                      )}
                      id="validationCustom01"
                      placeholder="Wachtwoord"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setError(false);
                      }}
                    />
                  </div>
                )}
                {error && (
                  <div id="namelocation" className="flex items-center">
                    <img src={ErrorSvg} alt="error" className="danger" />
                    <span className="ml-1">{t("invalid_password")}</span>
                  </div>
                )}
                <p className="text-[#cccccc] mt-2 text-[12px]">
                  We sturen je een sms om je nummer te bevestigen.
                </p>
                <div>
                  <Button
                    type="submit"
                    className="text-[16px] font-semibold mt-5 btn bg-gradient-to-r shadow-md to-[#7721E5] from-[#6A1BD1] text-white w-full px-[20px] py-[14px] rounded-[8px]"
                  >
                    Verdergaan
                  </Button>
                </div>
              </div>
              <div className="mt-[30px] w-full flex justify-between items-center">
                <div className="w-[38%] bg-[#B9B6BF] h-1 rounded-full"></div>
                <p className="text-[#B9B6BF] font-semibold">Of</p>
                <div className="w-[38%] bg-[#B9B6BF] h-1 rounded-full"></div>
              </div>
              <div className="mt-[30px] flex flex-col gap-3">
                <AppleAuth />
                <GoogleAuth />
                <button
                  onClick={() => {
                    navigate("/email-login");
                  }}
                  className="rounded-[8px] text-white text-[16px] font-semibold gap-3 flex items-center w-full bg-transparent justify-center p-[14px] border-2 border-[#B9B6BF]"
                >
                  <Icon icon="heroicons:envelope" className="h-8 w-8" />
                  Doorgaan met e-mail
                </button>
                <button
                  onClick={() => {
                    navigate("/login");
                  }}
                  className="rounded-[8px] text-white text-[16px] font-semibold gap-3 flex items-center w-full bg-transparent justify-center p-[14px] border-2 border-[#B9B6BF]"
                >
                  <Icon icon="heroicons:key" className="h-8 w-8" />
                  Doorgaan met token
                </button>
              </div>
            </div>
          </form>
        </div>
      </header>
    </>
  );
};

export default PhoneLogin;
