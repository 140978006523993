import { useState } from "react";
import { Button } from "@chakra-ui/react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import ErrorSvg from "../assets/image svg/error.svg";
import { useTranslation } from "react-i18next";
import Icon from "../components/Icon";
import AppleAuth from "../components/AppleAuth";
import GoogleAuth from "../components/GoogleAuth";
import { supabase } from "../supabaseClient";

export default function EventLoginForm({ redirectUrl }) {
  const [error, setError] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [emailExists, setEmailExists] = useState(false);

  const nextStep = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const getUser = async () => {
      const possibleUser = await supabase
        .from("User")
        .select("email")
        .eq("email", email)
        .limit(1)
        .single();

      return possibleUser;
    };

    getUser().then((user) => {
      if (!user.data) {
        navigate(`/create-account?email=${email}&redirectUrl=${redirectUrl}`);
      } else {
        setEmailExists(true);
      }
    });
  };

  const signIn = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { data, error } = await supabase.auth.signInWithPassword({
      email: email,
      password: password,
    });

    if (error) {
      setError(true);
    } else {
      window.location.reload();
    }
  };
  return (
    <div className="flex flex-col w-full items-center pt-12 lg:pt-20 lg:mt-20 mt-12 ">
      <div className="mt-6 relative z-[999] w-full pr-4 pl-2">
        <div className="w-full flex justify-center">
          <div className="w-[399px] p-5 rounded-[8px] text-purple-600 bg-blue-100 text-[14px] border border-purple-600 flex items-start justify-start gap-3 !pb-3">
            <Icon icon="heroicons:information-circle" className="h-9 w-9" />
            Dit event is alleen beschikbaar voor onze community leden. Maak hieronder binnen 1 minuut een account aan.
          </div>
        </div>
      </div>
      <div className="w-full login_form !h-fit !items-start mt-6 p-4">
        <form
          className="form w-full"
          name="f1"
          onSubmit={emailExists ? signIn : nextStep}
          method="post"
        >
          <div className="lg">
            <div className="">
              <p className="flex protected">
                <span className="ml-1.5 text-[24px]">
                  Meld je aan of Log in
                </span>
              </p>
              <div>
                <input
                  type="email"
                  name="name"
                  className={clsx(
                    "!py-[15px] outline-none !text-white !border-none !bg-[#130826]",
                    error ? (email ? "" : "error-border") : ""
                  )}
                  id="validationCustom01"
                  placeholder="E-mail"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setError(false);
                  }}
                />
              </div>
              {emailExists && (
                <div className="mt-2">
                  <input
                    type="password"
                    name="password"
                    className={clsx(
                      "!py-[15px] outline-none !text-white !border-none !bg-[#130826]",
                      error ? (password ? "" : "error-border") : ""
                    )}
                    id="validationCustom01"
                    placeholder="Wachtwoord"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setError(false);
                    }}
                  />
                </div>
              )}
              {error && (
                <div id="namelocation" className="flex items-center">
                  <img src={ErrorSvg} alt="error" className="danger" />
                  <span className="ml-1">{t("invalid_password")}</span>
                </div>
              )}
              <div>
                <Button
                  type="submit"
                  className="text-[16px] font-semibold mt-5 btn premium-button text-white w-full px-[20px] py-[14px] rounded-[8px]"
                >
                  Verdergaan
                </Button>
              </div>
            </div>
            <div className="mt-[30px] w-full flex justify-between items-center">
              <div className="w-[38%] bg-[#B9B6BF] h-1 rounded-full"></div>
              <p className="text-[#B9B6BF] font-semibold">Of</p>
              <div className="w-[38%] bg-[#B9B6BF] h-1 rounded-full"></div>
            </div>
            <div className="mt-[30px] flex flex-col gap-3">
              <AppleAuth redirectUrl={redirectUrl} />
              <GoogleAuth redirectUrl={redirectUrl} />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
