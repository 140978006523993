import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../slices/userReducer";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../components/Logo";
import { CustomSelect } from "./home/Home";
import Icon from "../components/Icon";
import DateInput from "../components/DateInput";
import { cities } from "../cities";
import { availableGenres } from "../helpers/helpers";
import { supabase } from "../supabaseClient";

export default function ProfileInfo({ language, setLanguage }) {
  const user = useSelector(selectUser);

  const [formData, setFormData] = useState({
    first_name: user.first_name,
    last_name: user.last_name,
    date_of_birth: user.date_of_birth,
    gender: user.gender,
    location: user.location,
    genres: user.genres?.split(", ") ?? [],
  });

  const addOrRemoveGenre = (genre) => {
    if (formData.genres.includes(genre)) {
      const genreCopy = [...formData.genres];

      const i = formData.genres?.indexOf(genre);
      genreCopy.splice(i, 1);
      // Remove genre
      setFormData({ ...formData, genres: genreCopy });
    } else {
      // Add genre
      setFormData({ ...formData, genres: [...formData.genres, genre] });
    }
  };

  const navigate = useNavigate();

  const saveProfile = () => {
    supabase.auth
      .updateUser({
        data: {
          ...formData,
          genres: formData.genres?.join(", ") ?? "",
        },
      })
      .then((res) => {
        navigate("/profile");
        console.log("RESS", res);
      });
  };

  return (
    <div className="bg-[#12041f]">
      <div
        className="hero-section relative min-h-[100vh] "
        style={{ background: "#0A0315" }}
      >
        <div className="lg:hidden w-full flex items-center justify-center pt-12">
          <div className="flex justify-between w-[90%] items-center">
            <Link to="/profile">
              <Icon
                icon="heroicons:arrow-left"
                className="text-white h-12 w-12"
              />
            </Link>
            <Link to="/">
              <Logo />
            </Link>
            <CustomSelect
              options={[
                { value: "nl", label: "🇳🇱 Nederlands" },
                { value: "en", label: "🇺🇸 English" },
              ]}
              onSelect={(value) => {
                setLanguage(value);
              }}
            />
          </div>
        </div>
        <div className="mt-12 flex flex-col items-center">
          <div className="w-[90%]">
            <h2 className="text-[26px] text-white">Jouw profiel</h2>
            <div className="mt-[16px]">
              <h3 className="text-[18px] text-white">Voor- en achternaam</h3>
              <div className="mt-1">
                <input
                  type="text"
                  name="name"
                  className="text-[16px] !py-[15px] outline-none !text-neutral-200 !border border-gray-200 !rounded-b-none !bg-transparent"
                  id="validationCustom01"
                  placeholder="Voornaam"
                  value={formData.first_name}
                  onChange={(e) => {
                    setFormData({ ...formData, first_name: e.target.value });
                  }}
                />
                <input
                  type="text"
                  name="name"
                  className="text-[16px] !py-[15px] outline-none !text-neutral-200 !border border-gray-200 !rounded-t-none !border-t-0 !bg-transparent"
                  id="validationCustom01"
                  placeholder="Achternaam"
                  value={formData.last_name}
                  onChange={(e) => {
                    setFormData({ ...formData, last_name: e.target.value });
                  }}
                />
              </div>
              <div className="mt-[16px]">
                <h3 className="text-[18px] text-white">Geboortedatum</h3>
                <div className="mt-1">
                  <DateInput
                    className="text-[16px] !py-[15px] outline-none !text-neutral-200 !border-[1px] rounded-[4px] !bg-transparent"
                    dob={formData.date_of_birth}
                    setDob={(d) => {
                      setFormData({ ...formData, date_of_birth: d });
                    }}
                  />
                </div>
              </div>

              <div className="mt-[16px]">
                <h3 className="text-[18px] text-white">Gender</h3>
                <div className="mt-1">
                  <select
                    value={formData.gender}
                    onChange={(e) => {
                      setFormData({ ...formData, gender: e.target.value });
                    }}
                    className="text-[16px] !py-[15px] outline-none !text-neutral-200 !border rounded-[4px] w-full !bg-transparent"
                  >
                    <option value="Man">Man</option>
                    <option value="Vrouw">Vrouw</option>
                    <option value="Anders">Anders</option>
                  </select>
                </div>
              </div>
              <div className="mt-[16px]">
                <h3 className="text-[18px] text-white">City</h3>
                <div className="mt-1">
                  <select
                    value={formData.location}
                    onChange={(e) => {
                      setFormData({ ...formData, location: e.target.value });
                    }}
                    className="text-[16px] !py-[15px] outline-none !text-neutral-200 !border rounded-[4px] w-full !bg-transparent"
                  >
                    {cities.map((city) => {
                      return <option value={city.city}>{city.city}</option>;
                    })}
                  </select>
                </div>
              </div>

              <div className="mt-[16px]">
                <h3 className="text-[18px] text-white">Favoriete genres</h3>
                <div className="mt-1">
                  {availableGenres.map((genre, index) => {
                    return (
                      <div
                        className={`flex items-center justify-start gap-4 !p-[10px] outline-none !text-neutral-200 !border border-gray-500 w-full !bg-transparent ${
                          index % 2 > 0 ? "border-t-none" : "border-b-none"
                        } ${
                          index === 0
                            ? "border-t-[4px]"
                            : index === availableGenres.length - 1 &&
                              "border-b-[4px]"
                        }`}
                      >
                        <input
                          type="checkbox"
                          className="!m-0 !p-0 h-7 w-7 bg-transparent rounded-[4px]"
                          checked={formData.genres?.includes(genre)}
                          onChange={() => {
                            addOrRemoveGenre(genre);
                          }}
                        />
                        <h4 className="!text-[16px]">{genre}</h4>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  onClick={() => {
                    saveProfile();
                  }}
                  className="mb-[100px] mt-[24px] !disabled:cursor-not-allowed disabled:bg-[#56466B] text-[16px] font-semibold btn enabled:bg-gradient-to-r shadow-md enabled:to-[#7721E5] enabled:from-[#6A1BD1] text-white w-full px-[20px] py-[14px] rounded-[8px]"
                >
                  Opslaan
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
