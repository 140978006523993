import React from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../slices/userReducer";
import { Link } from "react-router-dom";
import WhatsappLogo from "../assets/whatsapp-logo.svg";
import Logo from "../components/Logo";
import { CustomSelect } from "./home/Home";
import Icon from "../components/Icon";

export default function Profile({ language, setLanguage }) {
  const user = useSelector(selectUser);

  return (
    <div className="bg-[#12041f]">
      <div
        className="hero-section relative min-h-[100vh] "
        style={{ background: "#0A0315" }}
      >
        <div className="lg:hidden w-full flex items-center justify-center pt-12">
          <div className="flex justify-between w-[90%] items-center">
            <Link to="https://linktr.ee/22nightmember" target="_blank">
              <img
                src={WhatsappLogo}
                className="w-12 h-12"
                alt="Whatsapp logo"
              />
            </Link>
            <Link to="/">
              <Logo />
            </Link>
            <CustomSelect
              options={[
                { value: "nl", label: "🇳🇱 Nederlands" },
                { value: "en", label: "🇺🇸 English" },
              ]}
              onSelect={(value) => {
                setLanguage(value);
              }}
            />
          </div>
        </div>
        <div className="mt-12 flex flex-col items-center">
          <div className="w-[90%]">
            <h3 className="text-[26px] text-white">Profiel</h3>
            <Link
              className="mt-12 flex items-center justify-between"
              to="/profile/me"
            >
              <div className="flex items-center justify-start gap-4">
                <div className="p-4 bg-gray-300 rounded-full">
                  <Icon
                    icon="heroicons:user"
                    className="text-black h-10 w-10"
                  />
                </div>
                <div>
                  <h4 className="text-[16px] leading-[22px] text-white">
                    {user.first_name}
                  </h4>
                  <p className="text-[14px] leading-[20px] text-purple-300">
                    Bekijk profiel
                  </p>
                </div>
              </div>
              <Icon
                icon="heroicons:chevron-right"
                className="h-10 w-10 text-gray-200"
              />
            </Link>
            <hr className="mt-[30px] border-[#302640]" />
            <div className="mt-[30px]">
              <Link className="mt-[12px] flex items-center justify-between">
                <div className="flex items-center justify-start gap-4">
                  <div className="p-4">
                    <Icon
                      icon="heroicons:bell-alert"
                      className="text-gray-200 h-10 w-10"
                    />
                  </div>
                  <div>
                    <h4 className="text-[18px] leading-[22px] text-white">
                      Ticket alerts
                    </h4>
                  </div>
                </div>
                <Icon
                  icon="heroicons:chevron-right"
                  className="h-10 w-10 text-gray-200"
                />
              </Link>
              <Link
                className="mt-[12px] flex items-center justify-between"
                to="/profile/events"
              >
                <div className="flex items-center justify-start gap-4">
                  <div className="p-4">
                    <Icon
                      icon="ph:confetti"
                      className="text-gray-200 h-10 w-10"
                    />
                  </div>
                  <div>
                    <h4 className="text-[18px] leading-[22px] text-white">
                      Afgelopen events
                    </h4>
                  </div>
                </div>
                <Icon
                  icon="heroicons:chevron-right"
                  className="h-10 w-10 text-gray-200"
                />
              </Link>
              <Link
                to="https://linktr.ee/22nightmember"
                className="mt-[12px] flex items-center justify-between"
                target="_blank"
              >
                <div className="flex items-center justify-start gap-4">
                  <div className="p-4">
                    <Icon
                      icon="heroicons:key"
                      className="text-gray-200 h-10 w-10"
                    />
                  </div>
                  <div>
                    <h4 className="text-[18px] leading-[22px] text-white">
                      Invite friends
                    </h4>
                  </div>
                </div>
                <Icon
                  icon="heroicons:chevron-right"
                  className="h-10 w-10 text-gray-200"
                />
              </Link>
              <Link
                className="mt-[12px] flex items-center justify-between"
                to="https://wa.me/31617476764"
                target="_blank"
              >
                <div className="flex items-center justify-start gap-4">
                  <div className="p-4">
                    <Icon
                      icon="heroicons:chat-bubble-left-ellipsis"
                      className="text-gray-200 h-10 w-10"
                    />
                  </div>
                  <div>
                    <h4 className="text-[18px] leading-[22px] text-white">
                      Support
                    </h4>
                  </div>
                </div>
                <Icon
                  icon="heroicons:chevron-right"
                  className="h-10 w-10 text-gray-200"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
