import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Icon from "../components/Icon";
import DatePicker from "react-datepicker";
import { availableGenres, convertToUTC, getPublicImageUrl } from "../helpers/helpers";
import { v4 as uuidv4 } from "uuid";
import SuccessPopup from "../components/SuccessPopup";
import { Table } from "flowbite-react";
import { supabase } from "../supabaseClient";
import dayjs from "dayjs";

export default function ManageEventDetail() {
  const { id } = useParams();

  const [event, setEvent] = useState();

  const navigate = useNavigate();

  // Can be details or attendees
  const [activeTab, setActiveTab] = useState("details");
  const [error, setError] = useState([]);
  const [venues, setVenues] = useState([]);
  const [success, setSuccess] = useState(false);
  const [cities, setCities] = useState([]);
  const [signups, setSignups] = useState([]);

  useEffect(() => {
    supabase
      .from("EventSignUp")
      .select("*, event:Events (*)")
      .eq("event_id", id)
      .then((res) => {
        console.log(res.data);
        setSignups(res.data);
      });
  }, [id]);

  useEffect(() => {
    supabase
      .from("Events")
      .select("*, attendees:EventSignUp(*)")
      .eq("id", id)
      .then(({ data, error }) => {
        if (error) {
          console.error(error);
        } else {
          setEvent({ ...data[0], genres: data[0].genres.split(", ") });
        }
      });
  }, [id]);

  useEffect(() => {
    supabase
      .from("Venue")
      .select("*")
      .then((res) => {
        setVenues(res.data);
      });

    supabase
      .from("City")
      .select("*")
      .then((res) => {
        setCities(res.data);
      });
  }, []);

  const saveEvent = () => {
    const optionalFields = [
      "coupon_code",
      "artists",
      "sold_out",
      "trending",
      "exclusive_event",
    ];

    // Guestlists dont have a ticket link
    if (event.category === "Guestlist") {
      optionalFields.push("ticket_link");
    }

    // Error handling
    let hasError = false;
    Object.values(event).forEach((item, index) => {
      if (item === "" && !optionalFields.includes(Object.keys(event)[index])) {
        setError((prev) => {
          return [...prev, Object.keys(event)[index]];
        });
        hasError = true;
      } else {
        setError((prev) => {
          return prev.filter((error) => error !== Object.keys(event)[index]);
        });
      }
    });

    if (hasError) {
      return;
    }

    delete event["venue"];
    delete event["attendees"];

    // Convert the time to UTC before sending to the server
    const utcDateTime = convertToUTC(event.datetime);
    const utcSignupFrom = convertToUTC(event.signup_from);
    const utcSignupUntil = convertToUTC(event.signup_until);

    // Create a new object with the UTC datetime
    const eventToSave = {
      ...event,
      datetime: utcDateTime,
      signup_from: utcSignupFrom,
      signup_until: utcSignupUntil,
    };

    supabase
      .from("Events")
      .update({ ...eventToSave, genres: event.genres.join(", ") })
      .eq("id", event.id)
      .then((response) => {
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 2000);
      });
  };

  const uploadImage = (file) => {
    const uuid = uuidv4();

    const asset_name = `${uuid}-${file.name}`;

    supabase.storage.from("images").upload(asset_name, file, { upsert: true });

    setEvent({ ...event, event_image_filename: asset_name });
  };

  const addOrRemoveGenre = (genre) => {
    if (event.genres.includes(genre)) {
      const genreCopy = [...event.genres];

      const i = event.genres.indexOf(genre);
      genreCopy.splice(i, 1);
      // Remove genre
      setEvent({ ...event, genres: genreCopy });
    } else {
      // Add genre
      setEvent({ ...event, genres: [...event.genres, genre] });
    }
  };

  const validateAndUploadImage = (e) => {
    const file = e.target.files[0];

    if (file) {
      const img = new Image();
      const objectUrl = URL.createObjectURL(file);

      img.onload = () => {
        if (img.width === 975 && img.height === 525) {
          // Proceed with upload if the dimensions are correct
          uploadImage(file);
        } else {
          setError((prev) => {
            return [...prev, "event_image_filename"];
          });
          alert(
            `Image must be 975px by 525px! The image you uploaded is ${img.width}px by ${img.height}px.`
          );
        }

        URL.revokeObjectURL(objectUrl); // Clean up the object URL
      };

      img.src = objectUrl;
    }
  };

  const formatDateTime = (datetime) => {
    return dayjs(datetime).local("nl").format("D MMMM YYYY");
  };

  useEffect(()=>{
    console.log("formData", event?.datetime)
  },[event])

  return (
    <>
      {event && (
        <div className="px-[100px] py-[50px]">
          <div className="flex justify-between items-center w-full">
            <div className="flex items-center justify-start gap-5">
              <button
                onClick={() => navigate(-1)}
                className="p-2 hover:bg-white/20 rounded-lg"
              >
                <Icon
                  icon="heroicons:arrow-long-left"
                  className="text-white w-12 h-12"
                />
              </button>
              <h1 className="text-white !text-5xl font-semibold !text-start w-fit">
                {event?.name}
              </h1>
            </div>
          </div>
          <div className="flex justify-start items-center gap-3 mt-12 text-[18px]">
            <button
              onClick={() => setActiveTab("details")}
              className={`border-b-2 p-3 text-white ${
                activeTab === "details" ? "border-white" : "border-transparent"
              }`}
            >
              Details
            </button>
            <button
              onClick={() => setActiveTab("attendees")}
              className={`border-b-2 p-3 text-white ${
                activeTab === "attendees"
                  ? "border-white"
                  : "border-transparent"
              }`}
            >
              Attendees ({event?.attendees?.length})
            </button>
          </div>
          {activeTab === "details" && (
            <div className="mt-12">
              <h2 className="text-white text-[25px] font-semibold">Details</h2>
              <div className="">
                <div className="mt-6 flex flex-col justify-start gap-1">
                  <label className="text-xl text-neutral-500 mb-0">
                    Event name
                  </label>
                  <input
                    placeholder="Event name"
                    value={event.name}
                    type="text"
                    className={`dark-input ${
                      error.includes("name") ? "border-red-500" : ""
                    }`}
                    onChange={(e) => {
                      setEvent({ ...event, name: e.target.value });
                    }}
                  />
                </div>

                <div className="mt-6 flex flex-col justify-start gap-1">
                  <label className="text-xl text-neutral-500 mb-0">
                    Event description
                  </label>
                  <textarea
                    value={event.description}
                    placeholder="Event description"
                    rows="5"
                    className={`dark-input ${
                      error.includes("description") ? "border-red-500" : ""
                    }`}
                    onChange={(e) => {
                      setEvent({ ...event, description: e.target.value });
                    }}
                  />
                </div>

                <div className="mt-6 flex flex-col justify-start gap-1">
                  <label className="text-xl text-neutral-500 mb-0">
                    Artists (comma separated)
                  </label>
                  <textarea
                    value={event.artists}
                    placeholder="Hardwell, Martin Garrix, Tiesto ..."
                    rows="5"
                    className={`dark-input ${
                      error.includes("artists") ? "border-red-500" : ""
                    }`}
                    onChange={(e) => {
                      setEvent({ ...event, artists: e.target.value });
                    }}
                  />
                </div>

                <div className="mt-6 flex flex-col justify-start gap-1">
                  <label className="text-xl text-neutral-500 mb-0">City</label>
                  <select
                    className={`dark-input ${
                      error.includes("city")
                        ? "border-red-500"
                        : "border-gray-300"
                    }`}
                    value={event.city}
                    onChange={(e) => {
                      setEvent({ ...event, city: e.target.value });
                    }}
                  >
                    <option value="">Choose a city</option>
                    {cities?.map((city) => {
                      return <option value={city.name}>{city.name}</option>;
                    })}
                  </select>
                </div>

                <div className="mt-6 flex flex-col justify-start gap-1">
                  <label className="text-xl text-neutral-500 mb-0">
                    Date & time
                  </label>
                  <DatePicker
                    selected={event.datetime && new Date(event.datetime)}
                    onChange={(date) => {
                      setEvent({ ...event, datetime: date });
                    }}
                    showTimeSelect
                    dateFormat="d MMMM YYYY HH:mm"
                    className={`dark-input ${
                      error.includes("datetime")
                        ? "border-red-500"
                        : "border-gray-300"
                    }`}
                  />
                </div>

                <div className="mt-6 flex flex-col justify-start gap-1">
                  <label className="text-xl text-neutral-500 mb-0">
                    Signup from
                  </label>
                  <DatePicker
                    selected={event.signup_from && new Date(event.signup_from)}
                    onChange={(date) => {
                      setEvent({ ...event, signup_from: date });
                    }}
                    showTimeSelect
                    dateFormat="d MMMM YYYY HH:mm"
                    className={`dark-input ${
                      error.includes("signup_from")
                        ? "border-red-500"
                        : "border-gray-300"
                    }`}
                  />
                </div>

                <div className="mt-6 flex flex-col justify-start gap-1">
                  <label className="text-xl text-neutral-500 mb-0">
                    Signup until
                  </label>
                  <DatePicker
                    selected={
                      event.signup_until && new Date(event.signup_until)
                    }
                    onChange={(date) => {
                      setEvent({ ...event, signup_until: date });
                    }}
                    showTimeSelect
                    dateFormat="d MMMM YYYY HH:mm"
                    className={`dark-input ${
                      error.includes("signup_until")
                        ? "border-red-500"
                        : "border-gray-300"
                    }`}
                  />
                </div>

                <div className="mt-6 flex flex-col justify-start gap-1">
                  <label className="text-xl text-neutral-500 mb-0">
                    Minimum age
                  </label>
                  <input
                    type="number"
                    className={`dark-input ${
                      error.includes("minimum_age")
                        ? "border-red-500"
                        : "border-gray-300"
                    }`}
                    value={event.minimum_age}
                    onChange={(e) => {
                      setEvent({ ...event, minimum_age: e.target.value });
                    }}
                  />
                </div>

                <div className="mt-6 flex flex-col justify-start gap-1">
                  <label className="text-xl text-neutral-500 mb-0">
                    Event type
                  </label>
                  <select
                    className={`dark-input ${
                      error.includes("event_type")
                        ? "border-red-500"
                        : "border-gray-300"
                    }`}
                    value={event.event_type}
                    onChange={(e) => {
                      setEvent({ ...event, event_type: e.target.value });
                    }}
                  >
                    <option value="">Choose event type</option>
                    <option value="Nacht event">Nacht event</option>
                    <option value="Dag event">Dag event</option>
                    <option value="Festival">Festival</option>
                  </select>
                </div>
                <div className="mt-6 flex flex-col justify-start gap-1">
                  <label className="text-xl text-neutral-500 mb-0">
                    Category
                  </label>
                  <select
                    className={`dark-input ${
                      error.includes("category")
                        ? "border-red-500"
                        : "border-gray-300"
                    }`}
                    value={event.category}
                    onChange={(e) => {
                      setEvent({ ...event, category: e.target.value });
                    }}
                  >
                    <option value="">Choose event category</option>
                    <option value="Community ticket">Community ticket</option>
                    <option value="Guestlist">Guestlist</option>
                  </select>
                </div>

                {event.category === "Community ticket" && (
                  <div className="mt-6 flex flex-col justify-start gap-1">
                    <label className="text-xl text-neutral-500 mb-0">
                      Ticket link
                    </label>
                    <input
                      placeholder="Ticket link"
                      value={event.ticket_link}
                      type="text"
                      className={`dark-input ${
                        error.includes("ticket_link")
                          ? "border-red-500"
                          : "border-gray-300"
                      }`}
                      onChange={(e) => {
                        setEvent({ ...event, ticket_link: e.target.value });
                      }}
                    />
                  </div>
                )}

                <div className="mt-6 flex flex-col justify-start gap-1">
                  <label className="text-xl text-neutral-500 mb-0">
                    Trending
                  </label>
                  <input
                    checked={event.trending}
                    type="checkbox"
                    className={`dark-checkbox ${
                      error.includes("trending")
                        ? "border-red-500"
                        : "border-gray-300"
                    }`}
                    onChange={(e) => {
                      setEvent({ ...event, trending: e.target.checked });
                    }}
                  />
                </div>

                <div className="mt-6 flex flex-col justify-start gap-1">
                  <label className="text-xl text-neutral-500 mb-0">
                    Sold out
                  </label>
                  <input
                    checked={event.sold_out}
                    type="checkbox"
                    className={`dark-checkbox ${
                      error.includes("sold_out")
                        ? "border-red-500"
                        : "border-gray-300"
                    }`}
                    onChange={(e) => {
                      setEvent({ ...event, sold_out: e.target.checked });
                    }}
                  />
                </div>

                <div className="mt-6 flex flex-col justify-start gap-1">
                  <label className="text-xl text-neutral-500 mb-0">
                    Original price
                  </label>
                  <input
                    value={event.original_price}
                    type="text"
                    placeholder="50"
                    className={`dark-input ${
                      error.includes("original_price")
                        ? "border-red-500"
                        : "border-gray-300"
                    }`}
                    onChange={(e) => {
                      setEvent({
                        ...event,
                        original_price: e.target.value,
                      });
                    }}
                  />
                </div>

                <div className="mt-6 flex flex-col justify-start gap-1">
                  <label className="text-xl text-neutral-500 mb-0">
                    Our price
                  </label>
                  <input
                    value={event.our_price}
                    type="text"
                    placeholder="22,50"
                    className={`dark-input ${
                      error.includes("our_price")
                        ? "border-red-500"
                        : "border-gray-300"
                    }`}
                    onChange={(e) => {
                      setEvent({ ...event, our_price: e.target.value });
                    }}
                  />
                </div>

                <div className="mt-6 flex flex-col justify-start gap-1">
                  <label className="text-xl text-neutral-500 mb-0">
                    Coupon code (optional)
                  </label>
                  <input
                    value={event.coupon_code}
                    type="text"
                    className={`dark-input ${
                      error.includes("coupon_code")
                        ? "border-red-500"
                        : "border-gray-300"
                    }`}
                    onChange={(e) => {
                      setEvent({ ...event, coupon_code: e.target.value });
                    }}
                  />
                </div>

                <div className="mt-6 flex flex-col justify-start gap-1">
                  <label className="text-xl text-neutral-500 mb-0">Venue</label>
                  <select
                    className={`dark-input ${
                      error.includes("venue_id")
                        ? "border-red-500"
                        : "border-gray-300"
                    }`}
                    value={event.venue_id}
                    onChange={(e) => {
                      setEvent({ ...event, venue_id: e.target.value });
                    }}
                  >
                    <option value="">Choose a venue</option>
                    {venues.map((venue) => {
                      return <option value={venue.id}>{venue.name}</option>;
                    })}
                  </select>
                </div>

                <div className="mt-6 flex flex-col justify-start gap-1">
                  <label className="text-xl text-neutral-500 mb-0">
                    Exclusive event
                  </label>
                  <input
                    checked={event.exclusive_event}
                    type="checkbox"
                    className={`dark-checkbox ${
                      error.includes("exclusive_event")
                        ? "border-red-500"
                        : "border-gray-300"
                    }`}
                    onChange={(e) => {
                      setEvent({
                        ...event,
                        exclusive_event: e.target.checked,
                      });
                    }}
                  />
                </div>

                <div className="mt-6 flex flex-col justify-start gap-1">
                  <label className="text-xl text-neutral-500 mb-0">
                    Genres
                  </label>
                  <div className="flex flex-wrap gap-2">
                    {availableGenres.map((genre) => {
                      return (
                        <button
                          onClick={() => {
                            addOrRemoveGenre(genre);
                          }}
                          className={`${
                            event.genres.includes(genre)
                              ? "bg-green-700 hover:bg-red-700"
                              : "bg-gray-700 hover:bg-green-700"
                          } transition-all text-white text-2xl p-2`}
                        >
                          {genre}
                        </button>
                      );
                    })}
                  </div>
                </div>

                {/* Genre */}

                {/* Event image */}
                <div className="mt-6 flex flex-col justify-start gap-1">
                  <label className="text-xl text-neutral-500 mb-0">
                    Event image (ignore that it doesnt show the title)
                  </label>
                  <input
                    type="file"
                    accept=".jpeg, .jpg, .png"
                    className={`dark-input ${
                      error.includes("event_image_filename")
                        ? "border-red-500"
                        : "border-gray-300"
                    }`}
                    onChange={(e) => {
                      validateAndUploadImage(e);
                    }}
                  />
                  {event.event_image_filename && (
                    <img
                      src={getPublicImageUrl(event.event_image_filename)}
                      className="w-[200px] h-auto"
                      alt={""}
                    />
                  )}
                </div>

                {error.length > 0 && (
                  <p className="text-3xl font-semibold mt-5 text-red-500">
                    Please fill in all fields correctly.
                  </p>
                )}

                <div className="flex items-center justify-end w-full pt-9">
                  <button
                    onClick={() => {
                      saveEvent();
                    }}
                    className="text-white transition-all px-6 lg:px-12 py-3 rounded-lg text-3xl font-semibold hover:bg-[#38c28f] bg-[#07af71]"
                  >
                    Save event
                  </button>
                </div>
              </div>
            </div>
          )}
          {activeTab === "attendees" && (
            <div className="mt-12">
              <Table className="mt-8 bg-black">
                <Table.Head>
                  <Table.HeadCell className="text-3xl capitalize">
                    Date of signup
                  </Table.HeadCell>
                  <Table.HeadCell className="text-3xl capitalize">
                    Name
                  </Table.HeadCell>
                  <Table.HeadCell className="text-3xl capitalize">
                    Date of event
                  </Table.HeadCell>
                  <Table.HeadCell className="text-3xl capitalize">
                    Email
                  </Table.HeadCell>
                  <Table.HeadCell className="text-3xl capitalize">
                    Date of birth
                  </Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                  {signups?.map((signup) => {
                    return (
                      <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                        <Table.Cell className="p-8 text-2xl whitespace-nowrap font-medium text-gray-900 dark:text-white">
                          {formatDateTime(signup.created_at)}
                        </Table.Cell>
                        <Table.Cell className="p-8 text-2xl whitespace-nowrap font-medium text-gray-900 dark:text-white">
                          {`${signup.first_name} ${signup.last_name}`}
                        </Table.Cell>
                        <Table.Cell className="p-8 text-2xl whitespace-nowrap font-medium text-gray-900 dark:text-white">
                          {formatDateTime(signup.event.datetime)}
                        </Table.Cell>
                        <Table.Cell className="p-8 text-2xl whitespace-nowrap font-medium text-gray-900 dark:text-white">
                          {signup.email}
                        </Table.Cell>
                        <Table.Cell className="p-8 text-2xl whitespace-nowrap font-medium text-gray-900 dark:text-white">
                          {signup.date_of_birth}
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </div>
          )}
        </div>
      )}
      <SuccessPopup show={success} message="Event updated successfully" />
    </>
  );
}
