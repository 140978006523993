import EventItem from "./EventItem";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { adjustTime } from "../helpers/helpers";
import "dayjs/locale/nl";
import { useEffect, useState } from "react";
dayjs.extend(utc);
dayjs.extend(timezone);

const EventsList = ({
  eventsData,
  currentCity,
  isDivider,
  formattedCurrentDate,
  language,
}) => {
  const [registerableEvents, setRegisterableEvents] = useState([]);

  useEffect(() => {
    setRegisterableEvents(
      eventsData
        .filter((event) => {
          const registerFrom = adjustTime(event.signup_from);
          const registerUntil = adjustTime(event.signup_until);
          // console.log(event.EvenName,'registerFRom', registerFrom, 'registerUntil', registerUntil, 'formattedCurrentDate', formattedCurrentDate)
          return (
            formattedCurrentDate > registerFrom &&
            formattedCurrentDate < registerUntil
          );
        })
        .map((event) => {
          const dateObject = dayjs(event.datetime).utc().locale(language);

          return {
            ...event,
            fulldate: {
              date: dateObject.format("D MMMM"),
              day: dateObject.format("dddd"),
            },
          };
        })
    );
  }, [eventsData, formattedCurrentDate, language]);

  const getEventDate = (datetime) => {
    return dayjs(datetime).locale(language).format("D MMMM");
  };

  const getEventDay = (datetime) => {
    return dayjs(datetime).locale(language).format("dddd");
  };

  const groupedByDate = registerableEvents.reduce((grouped, event) => {
    const date = getEventDate(event.datetime);
    const timestamp = dayjs(event.datetime).valueOf();

    if (!grouped[date]) {
      grouped[date] = {
        date: date,
        day: getEventDay(event.datetime),
        timestamp: timestamp,
        data: [],
      };
    }

    grouped[date].data.push(event);

    return grouped;
  }, {});

  const groupedArray = Object.values(groupedByDate);

  // Sort the groups based on the timestamp (date) of the events
  groupedArray.sort((a, b) => a.timestamp - b.timestamp);

  // Sort events within each group by their datetime
  groupedArray.forEach((group) => {
    group.data.sort(
      (a, b) => dayjs(a.datetime).valueOf() - dayjs(b.datetime).valueOf()
    );
  });

  return (
    <div className="custom-container">
      {groupedArray.map((eventsGroup) => {
        return (
          <EventItem
            key={eventsGroup.id}
            eventsGroupData={eventsGroup}
            currentCity={currentCity}
            language={language}
          />
        );
      })}
      {isDivider && <div className="card-divider"></div>}
    </div>
  );
};

export default EventsList;
