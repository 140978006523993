import { handleMenuHidden } from "../slices/themeSlice";
import { useSelector, useDispatch } from "react-redux";

const useMenuHidden = () => {
  const dispatch = useDispatch();
  const contentWidth = useSelector((state) => state.theme.menuHidden);

  // ** Toggles Content Width
  const setContentWidth = (val) => dispatch(handleMenuHidden(val));

  return [contentWidth, setContentWidth];
};

export default useMenuHidden;
