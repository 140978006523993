import React, { useEffect, useState } from "react";
import { Table } from "flowbite-react";
import { supabase } from "../supabaseClient";
import Icon from "../components/Icon";

export default function ManageSuggestions() {
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    supabase
      .from("Suggestion")
      .select("*")
      .then((res) => {
        setSuggestions(res.data);
      });
  }, []);

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this suggestion?")) {
      supabase
        .from("Suggestion")
        .delete()
        .eq("id", id)
        .then((res) => {
          setSuggestions(
            suggestions.filter((suggestion) => suggestion.id !== id)
          );
        });
    }
  };

  return (
    <div className="w-full text-white bg-black text-3xl mt-12 overflow-x-auto p-5">
      <div className="flex justify-between items-center w-full">
        <h1 className="text-white !text-5xl font-semibold !text-start w-fit">
          Suggestions
        </h1>
      </div>
      <Table className="mt-8">
        <Table.Head>
          <Table.HeadCell className="text-3xl capitalize w-[80%]">
            Event
          </Table.HeadCell>
          <Table.HeadCell className="text-3xl capitalize w-[80%]">
            Email
          </Table.HeadCell>
          <Table.HeadCell className="text-3xl capitalize w-[10%]">
            Phone number
          </Table.HeadCell>
          <Table.HeadCell className="text-3xl capitalize w-[10%]">
            Checked
          </Table.HeadCell>
          <Table.HeadCell className="text-3xl capitalize w-[10%]">
            Delete
          </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {suggestions?.map((suggestion, index) => {
            return (
              <Table.Row
                className={`${index % 2 === 0 ? "bg-white" : "bg-gray-200"}`}
              >
                <Table.Cell className="p-8 text-2xl whitespace-nowrap font-medium text-gray-900">
                  {suggestion.event}
                </Table.Cell>
                <Table.Cell className="p-8 text-2xl whitespace-nowrap font-medium text-gray-900">
                  {suggestion.email}
                </Table.Cell>
                <Table.Cell className="p-8 text-2xl whitespace-nowrap font-medium text-gray-900">
                  {suggestion.phone_number}
                </Table.Cell>
                <Table.Cell className="p-8 text-2xl whitespace-nowrap font-medium text-gray-900">
                  <input
                    type="checkbox"
                    className="w-8 h-8 hover:cursor-pointer"
                    checked={suggestion.checked}
                    onChange={async (e) => {
                      const newChecked = e.target.checked;
                      await supabase
                        .from("Suggestion")
                        .update({ checked: newChecked })
                        .eq("id", suggestion.id);
                      setSuggestions(suggestions.map(s =>
                        s.id === suggestion.id
                          ? {...s, checked: newChecked}
                          : s
                      ));
                    }}
                  />
                </Table.Cell>
                <Table.Cell className="p-8 text-2xl whitespace-nowrap font-medium text-red-500">
                  <button onClick={() => handleDelete(suggestion.id)}>
                    <Icon icon="heroicons:trash" className="w-10 h-10" />
                  </button>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
}
