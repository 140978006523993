import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Scrollbar, A11y } from "swiper";

import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getPublicImageUrl } from "../helpers/helpers";

export default function DealsOfTheWeek({ dealsOfTheWeek }) {
  const navigate = useNavigate();

  const {t} = useTranslation();

  return (
    <section className="max-lg:px-[10px]">
      <h1 className="pb-[20px] text-start text-[22px] leading-[26px] lg:text-[32px] lg:leading-[36px] w-full">
      {t('deals_of_the_week')}
      </h1>
      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        breakpoints={{
          // when window width is >= 640px
          340: {
            // width: 640,
            slidesPerView: 1.1,
          },
          // when window width is >= 768px
          768: {
            width: 768,
            slidesPerView: 2.6,
          },
        }}
        modules={[Navigation, Scrollbar, A11y]}
        // slidesPerView={'2.5'}
        navigation={dealsOfTheWeek.length > 2 ? true : false}
        pagination={{
          clickable: true,
        }}
        scrollbar={{ draggable: true }}
        isDuplicate={dealsOfTheWeek.length > 2 ? true : false}
        // onSlideChange={(swiper) => disableSwiperBtns(swiper.activeIndex)}
        className="custom-arrow"
        // spaceBetween={10}
      >
        {dealsOfTheWeek.map((item, index) => (
          <SwiperSlide key={index} className="!w-[400px] !max-w-[400px]">
            <div
              onClick={() => {
                navigate(`/deal?deal_id=${item.id}`);
              }}
              className="max-lg:!max-w-[294px] hover:cursor-pointer h-[200px] !max-w-[400px] !w-[400px] relative hover:scale-[102%] transition-all duration-500"
            >
              <img
                src={getPublicImageUrl(item.image_filename)}
                className="absolute top-0 left-0 w-full h-full rounded-2xl object-cover brightness-[60%]"
                alt="Placeholder"
              />
              <div className="w-full flex flex-col gap-y-1 absolute left-[0] bottom-[0] p-[20px] z-100 rounded-b-2xl">
                <h2 className="text-[18px] leading-[22px] text-white font-semibold">
                  {item.name}
                </h2>
                <p className="text-[14px] leading-[16px] text-white font-medium">
                  {item.teaser}
                </p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
}
