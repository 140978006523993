import DatumIcon from "../assets/image svg/Calendar-N.svg";
import CategoryIcon from "../assets/image svg/party.svg";
import GenreIcon from "../assets/image svg/Music.svg";
import UserIcon from "../assets/image svg/User.svg";

import ArrowDownIcon from "../assets/image svg/chevron down.svg";
import LocationIcon from "../assets/image svg/Locatie.svg";
import {
  Button,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { supabase } from "../supabaseClient";

const DiscoverEvent = ({ filters, setFilters, city, genres, changeCity }) => {
  const { t } = useTranslation();

  const filterMenus = {
    datum: [
      t("today"),
      t("tomorrow"),
      t("this_week"),
      t("next_week"),
      t("this_month"),
      t("all_dates"),
    ],
    categorie: [
      t("day_event"),
      t("night_event"),
      t("festival"),
      t("all_events"),
    ],
    leeftijd: ["18+", "21+", "23+", t("all_ages")],
  };

  // const genreList = filterMenus.genre;
  const genreList = genres;
  const [selectedGenres, setSelectedGenres] = useState([...genres]);

  const [cities, setCities] = useState([]);

  // Check if genres contains data, and if so, set it as the initial state
  useEffect(() => {
    if (genres && genres.length > 0) {
      setSelectedGenres([...genres]);
    }
  }, [genres]);

  const genreChange = (selectedItems) => {
    setSelectedGenres(selectedItems);
    updateFilters(selectedItems, "genre");
  };

  const updateFilters = (item, category) => {
    setFilters((prev) => {
      return { ...prev, [category]: item };
    });
  };

  // refreshes filters when city changes
  useEffect(() => {
    setFilters({
      datum: t("all_dates"),
      categorie: t("all_events"),
      genre: [...genreList],
      leeftijd: t("all_ages"),
      city: city,
    });
    setSelectedGenres([...genreList]);
  }, [city]);

  useEffect(() => {
    supabase
      .from("City")
      .select("*")
      .then((res) => {
        setCities(res.data.map((city) => city.name));
      });
  }, []);

  return (
    <div className="custom-container !mt-[24px]">
      <h2 className="text-white ontdek">{t("discover_events")}</h2>
      <div className="wrapper">
        <div className="external-dropdown">
          <div className="outer">
            {/* sort by dates */}
            <div className="side filter-date  mr-5">
              <Menu>
                <MenuButton
                  as={Button}
                  className="btn btn-dropdown dropdown-toggle"
                  leftIcon={<img src={LocationIcon} alt="locatie" />}
                  rightIcon={<img src={ArrowDownIcon} alt="arrow down" />}
                >
                  <span className="selectedOption" id="button1Text">
                    {city}
                  </span>
                </MenuButton>
                <MenuList className="menu-list" pos="absolute" top="8" left="0">
                  <MenuOptionGroup
                    className=""
                    defaultValue={city}
                    type="radio"
                  >
                    {cities.map((city, index) => (
                      <MenuItemOption
                        key={index}
                        onClick={() => {
                          changeCity(city);
                        }}
                        value={city}
                      >
                        {city}
                      </MenuItemOption>
                    ))}
                  </MenuOptionGroup>
                </MenuList>
              </Menu>
            </div>
            <div className="side filter-date  mr-5">
              <Menu>
                <MenuButton
                  as={Button}
                  className="btn btn-dropdown dropdown-toggle"
                  leftIcon={<img src={DatumIcon} alt="datum" />}
                  rightIcon={<img src={ArrowDownIcon} alt="arrow down" />}
                >
                  <span className="selectedOption" id="button1Text">
                    {filters.datum}
                  </span>
                </MenuButton>
                <MenuList className="menu-list" pos="absolute" top="8" left="0">
                  <MenuOptionGroup
                    className=""
                    defaultValue={t("all_dates")}
                    type="radio"
                  >
                    {filterMenus.datum.map((item, index) => (
                      <MenuItemOption
                        key={index}
                        onClick={() => {
                          updateFilters(item, "datum");
                        }}
                        value={item}
                      >
                        {item}
                      </MenuItemOption>
                    ))}
                  </MenuOptionGroup>
                </MenuList>
              </Menu>
            </div>
            {/* sort by categories */}
            <div className="side filter-category mr-5">
              <Menu>
                <MenuButton
                  as={Button}
                  className="btn btn-dropdown dropdown-toggle"
                  leftIcon={<img src={CategoryIcon} alt="datum" />}
                  rightIcon={<img src={ArrowDownIcon} alt="arrow down" />}
                >
                  <span className="selectedOption" id="button1Text">
                    {filters.categorie}
                  </span>
                </MenuButton>
                <MenuList className="menu-list" pos="absolute" top="8" left="0">
                  <MenuOptionGroup
                    className=""
                    defaultValue={t("all_events")}
                    type="radio"
                  >
                    {filterMenus.categorie.map((item, index) => (
                      <MenuItemOption
                        key={index}
                        onClick={() => {
                          setFilters({ ...filters, categorie: item });
                          updateFilters(item, "categorie");
                        }}
                        value={item}
                      >
                        {item}
                      </MenuItemOption>
                    ))}
                  </MenuOptionGroup>
                </MenuList>
              </Menu>
            </div>
            {/* sort by genres */}
            <div className="side filter-genre  mr-5">
              <Menu closeOnSelect={false}>
                <MenuButton
                  as={Button}
                  className="btn btn-dropdown dropdown-toggle"
                  leftIcon={<img src={GenreIcon} alt="datum" />}
                  rightIcon={<img src={ArrowDownIcon} alt="arrow down" />}
                >
                  <span className="selectedOption" id="button1Text">
                    Genre
                  </span>
                </MenuButton>
                <MenuList
                  className="menu-list menu-checkbox-list"
                  pos="absolute"
                  top="8"
                  left="0"
                >
                  <MenuOptionGroup
                    className=""
                    type="checkbox"
                    // defaultValue={selectedGenres}
                    aria-multiselectable
                    value={selectedGenres}
                    onChange={(selectedItems) => genreChange(selectedItems)}
                  >
                    {genreList.map((item, index) => (
                      <MenuItemOption key={index} value={item}>
                        {item === "Hitjes" ? t("hits") : item}
                      </MenuItemOption>
                    ))}
                  </MenuOptionGroup>
                </MenuList>
              </Menu>
            </div>
            {/* sort by age */}
            <div className="side filter-age">
              <Menu>
                <MenuButton
                  as={Button}
                  className="btn btn-dropdown dropdown-toggle"
                  leftIcon={<img src={UserIcon} alt="datum" />}
                  rightIcon={<img src={ArrowDownIcon} alt="arrow down" />}
                >
                  <span className="selectedOption" id="button1Text">
                    {filters.leeftijd}
                  </span>
                </MenuButton>
                <MenuList className="menu-list" pos="absolute" top="8" left="0">
                  <MenuOptionGroup
                    className=""
                    defaultValue={t("all_ages")}
                    type="radio"
                  >
                    {filterMenus.leeftijd.map((item, index) => (
                      <MenuItemOption
                        key={index}
                        onClick={() => {
                          setFilters({ ...filters, leeftijd: item });
                          updateFilters(item, "leeftijd");
                          updateFilters(city, "city");
                        }}
                        value={item}
                      >
                        {item}
                      </MenuItemOption>
                    ))}
                  </MenuOptionGroup>
                </MenuList>
              </Menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiscoverEvent;
