import Logo from "./Logo";
import { Link } from "react-router-dom";
import DjSet from "../assets/dj-set.svg";
import FeedbackIcon from "../assets/feedback-icon.svg";
import ShoppingBag from "../assets/shopping-bag.svg";
import { useSelector } from "react-redux";
import { selectUser } from "../slices/userReducer";
import Icon from "./Icon";

const AdminSidebar = () => {
  const currentSlug = window.location.href.split("/").pop();

  const user = useSelector(selectUser);

  return (
    <div>
      <div
        className="fixed top-0 z-[999] h-screen bg-[#00000040] py-[64px] px-[24px] text-white lg:w-[300px] w-[0px] shadow-lg"
        style={{
          transition: "width 0.2s cubic-bezier(0.39, 0.575, 0.565, 1)",
        }}
      >
        <Link to="/" className="">
          <div className="ml-6">
            <Logo />
          </div>
        </Link>

        <div className="sidebar-menu h-auto mt-[32px]">
          <Link
            to="/admin/events"
            className={`mb-[16px] p-[12px] flex items-center justify-start gap-[12px] rounded-lg font-normal !text-white ${
              currentSlug === "events"
                ? "bg-[#D0ADFB20]"
                : "hover:bg-[#D0ADFB20]"
            } hover:shadow-lg`}
          >
            <img src={DjSet} className="h-[20px] w-[20px]" alt="DJ Set" />
            <p className="text-[16px]">Events</p>
          </Link>
          <Link
            to="/admin/lifestyle"
            className={`mb-[16px] p-[12px] flex items-center justify-start gap-[12px] rounded-lg font-normal !text-white ${
              currentSlug === "lifestyle"
                ? "bg-[#D0ADFB20]"
                : "hover:bg-[#D0ADFB20]"
            } hover:shadow-lg`}
          >
            <img
              src={ShoppingBag}
              className="h-[20px] w-[20px]"
              alt="Shopping bag"
            />

            <p className="text-[16px]">Lifestyle deals</p>
          </Link>
          <Link
            to="/admin/venues"
            className={`mb-[16px] p-[12px] flex items-center justify-start gap-[12px] rounded-lg font-normal !text-white ${
              currentSlug === "venues"
                ? "bg-[#D0ADFB20]"
                : "hover:bg-[#D0ADFB20]"
            } hover:shadow-lg`}
          >
            <img
              src={FeedbackIcon}
              className="h-[20px] w-[20px]"
              alt="Feedback icon"
            />
            <p className="text-[16px]">Venues</p>
          </Link>
          <Link
            to="/admin/cities"
            className={`mb-[16px] p-[12px] flex items-center justify-start gap-[12px] rounded-lg font-normal !text-white ${
              currentSlug === "cities"
                ? "bg-[#D0ADFB20]"
                : "hover:bg-[#D0ADFB20]"
            } hover:shadow-lg`}
          >
            <Icon icon="heroicons:map-pin" className="h-[20px] w-[20px]" />
            <p className="text-[16px]">Cities</p>
          </Link>
          <Link
            to="/admin/suggestions"
            className={`mb-[16px] p-[12px] flex items-center justify-start gap-[12px] rounded-lg font-normal !text-white ${
              currentSlug === "suggestions"
                ? "bg-[#D0ADFB20]"
                : "hover:bg-[#D0ADFB20]"
            } hover:shadow-lg`}
          >
            <Icon icon="heroicons:inbox-arrow-down" className="h-[20px] w-[20px]" />
            <p className="text-[16px]">Suggestions</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AdminSidebar;
