import React, { useEffect, useState } from "react";
import { supabase } from "../supabaseClient";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Icon as LeafletIcon } from "leaflet";

const defaultIcon = new LeafletIcon({
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

export default function EditVenueModal({
  show,
  setShow,
  create,
  currentVenue,
}) {
  const [formData, setFormData] = useState({
    name: "",
    city: "",
    street_address: "",
    zip_code: "",
    lat: 0,
    long: 0,
  });

  const [error, setError] = useState(false);

  useEffect(() => {
    if (!create && currentVenue) {
      console.log("CURRENTVENUE", currentVenue);
      setFormData({ ...currentVenue });
    }
  }, [currentVenue, create]);

  const saveVenue = () => {
    if (
      Object.values(formData).some((item, index) => {
        if (item === "") {
          return true;
        }
      })
    ) {
      setError(true);
      return;
    } else {
      setError(false);
    }

    if (create === true) {
      supabase
        .from("Venue")
        .insert({ ...formData })
        .then((response) => {
          setShow(false);
        });
    } else {
      supabase
        .from("Venue")
        .update({ ...formData })
        .eq("id", currentVenue.id)
        .then((response) => {
          console.log("RES", response);
          setShow(false);
        });
    }
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto "
        onClose={() => {
          setShow(false);
        }}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="w-full h-screen bg-[#00000040] fixed z-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-7xl sm:w-full sm:p-6">
              <div className="absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-12 w-12" aria-hidden="true" />
                </button>
              </div>
              <div className="mb-3 flex justify-center w-full">
                <div className="flex-col flex items-center w-full">
                  <div className="text-center w-full">
                    <h1 className="text-5xl font-bold text-black w-full">
                      {create ? "Create a new venue" : "Edit venue"}
                    </h1>
                  </div>
                </div>
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">Venue name</label>
                <input
                  placeholder="Venue name"
                  value={formData.name}
                  type="text"
                  className="text-2xl text-black bg-white border-2 border-gray-300 p-4 rounded-lg"
                  onChange={(e) => {
                    setFormData({ ...formData, name: e.target.value });
                  }}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">City</label>
                <input
                  placeholder="Eg. Amsterdam"
                  value={formData.city}
                  type="text"
                  className="text-2xl text-black bg-white border-2 border-gray-300 p-4 rounded-lg"
                  onChange={(e) => {
                    setFormData({ ...formData, city: e.target.value });
                  }}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">
                  Street address
                </label>
                <input
                  placeholder="Address"
                  value={formData.street_address}
                  type="text"
                  className="text-2xl text-black bg-white border-2 border-gray-300 p-4 rounded-lg"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      street_address: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">Zip code</label>
                <input
                  placeholder="Zip code"
                  value={formData.zip_code}
                  type="text"
                  className="text-2xl text-black bg-white border-2 border-gray-300 p-4 rounded-lg"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      zip_code: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">Longitude</label>
                <input
                  placeholder="Latitdue"
                  value={formData.long}
                  type="text"
                  className="text-2xl text-black bg-white border-2 border-gray-300 p-4 rounded-lg"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      long: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">Latitude</label>
                <input
                  placeholder="Latitude"
                  value={formData.lat}
                  type="text"
                  className="text-2xl text-black bg-white border-2 border-gray-300 p-4 rounded-lg"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      lat: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">
                  Preview location
                </label>
                <div>
                  {formData.long > 0 && formData.lat > 0 && (
                    <MapContainer
                      className="max-w-full max-h-[200px] !rounded-[8px] z-0"
                      center={[formData.long, formData.lat]}
                      zoom={11}
                      scrollWheelZoom={false}
                    >
                      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                      <Marker
                        icon={defaultIcon}
                        position={[formData.long, formData.lat]}
                      >
                        <Popup>{formData.name}</Popup>
                      </Marker>
                    </MapContainer>
                  )}
                </div>
              </div>

              {error && (
                <p className="text-3xl font-semibold mt-5 text-red-500">
                  Please fill in all fields.
                </p>
              )}

              <div className="flex items-center justify-end w-full pt-9">
                <button
                  onClick={() => {
                    saveVenue();
                  }}
                  className="text-white transition-all px-6 lg:px-12 py-3 rounded-lg text-3xl font-semibold hover:bg-[#38c28f] bg-[#07af71]"
                >
                  Save venue
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
