import { configureStore } from "@reduxjs/toolkit"
import themeSlice from "./slices/themeSlice"
import userReducer from "./slices/userReducer"

export default configureStore({
    reducer: {
        theme: themeSlice,
        user: userReducer
    }
})
