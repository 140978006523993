import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import Icon from "./Icon";

export default function FAQSection() {
  const items = [
    {
      question: "Wat is 22night.com?",
      answer:
        "22night.com is een exclusief platform waar leden van onze besloten community toegang krijgen tot goedkopere tickets en gastenlijsten voor festivals en clubs. We werken samen met evenementenorganisatoren om deze deals aan te bieden, maar alleen leden van onze community kunnen hiervan profiteren.",
    },
    {
      question: "Hoe kan ik lid worden van de 22night.com community?",
      answer:
        "Om lid te worden, moet je een account aanmaken en voldoen aan onze voorwaarden of een uitnodiging (token) ontvangen van een ambassadeur. Zonder deze stappen kun je geen toegang krijgen tot de website.",
    },
    {
      question: "Hoe werkt het aankoopproces?",
      answer:
        "Als je lid bent, kun je op de website evenementen selecteren en tickets of gastenlijstplaatsen kopen. Na de aankoop ontvang je je ticket per e-mail. Houd er rekening mee dat doorverkoop van tickets niet is toegestaan, omdat we de tickets tegen een gereduceerde prijs aanbieden.",
    },
    {
      question: "Kan ik mijn ticket doorverkopen of annuleren?",
      answer:
        "Nee, doorverkoop van tickets is niet toegestaan. Als je een ticket niet kunt gebruiken, neem dan contact op met de ticketprovider, omdat wij enkel een doorgeefluik zijn. De ticketprovider kan je informeren over eventuele annuleringen of andere mogelijkheden.",
    },
    {
      question: "Wat is een token en hoe krijg ik er een?",
      answer:
        "Een token is een unieke uitnodigingscode die je kunt krijgen van een ambassadeur. Hiermee krijg je toegang tot onze besloten community en kun je profiteren van de exclusieve deals op 22night.com.",
    },
    {
      question: "Wat houdt het ambassadeursprogramma in?",
      answer:
        "Ambassadeurs kunnen tokens uitdelen aan nieuwe leden om hen toegang te geven tot onze community. Als je ambassadeur wilt worden, kun je contact opnemen met ons team voor meer informatie over hoe je kunt deelnemen.",
    },
    {
      question: "Hoe vaak worden er nieuwe evenementen toegevoegd?",
      answer:
        "We voegen regelmatig nieuwe evenementen toe, afhankelijk van onze samenwerkingen met verschillende organisatoren. Houd de website en onze communicatiekanalen goed in de gaten om op de hoogte te blijven van nieuwe deals.",
    },
    {
      question: "Wat moet ik doen als ik technische problemen heb?",
      answer:
        "Bij technische problemen, zoals inlogproblemen of problemen met je account, kun je contact opnemen met onze klantenservice via de contactpagina. We helpen je graag verder om het probleem op te lossen.",
    },
  ];

  return (
    <>
      <h1 className="!text-[28px] font-semibold text-white text-center w-full">
        Over 22NIGHT
      </h1>
      <div className="mt-8 w-full divide-y divide-white/5 rounded-xl bg-white/5">
        {items.map((item, index) => (
          <Disclosure as="div" className="p-10" defaultOpen={index === 0}>
            <DisclosureButton className="group flex w-full items-center justify-between">
              <span className="max-w-[85%] text-start text-[16px] lg:text-[20px] font-medium text-white group-data-[hover]:text-white/80">
                {item.question}
              </span>
              <Icon
                icon="heroicons:chevron-down"
                className="h-10 w-10 text-white group-data-[hover]:text-white/50 group-data-[open]:rotate-180"
              />
            </DisclosureButton>
            <DisclosurePanel className="text-[14px] lg:text-[18px] text-white/50">
              {item.answer}
            </DisclosurePanel>
          </Disclosure>
        ))}
      </div>
    </>
  );
}
