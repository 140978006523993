import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    const element = document.documentElement; // Use document.body for older browsers
    element.scrollTop = 0;
  }, [pathname]);

  return null; // This component doesn't render anything
}

export default ScrollToTop;