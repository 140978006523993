import { useEffect, useState } from "react";
// import axios from 'axios';
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs";
import "dayjs/locale/nl";
import "./Home.css";
// import Cards from '../../components/Cards';
import DiscoverEvent from "../../components/DiscoverEvent";
import Carousel from "../../components/Carousel";
import SearchBar from "../../components/SearchBar";
import EventsList from "../../components/EventsList";
import Logo from "../../components/Logo";
import { adjustTime } from "../../helpers/helpers";
import NoEvents from "../../components/NoEvents";
import EventItemPreloader from "../../components/EventItemPreloader";
// import eventItems from '../../utils/mockupData.json';
import React from "react";
import { useTranslation } from "react-i18next";
import WhatsappLogo from "../../assets/whatsapp-logo.svg";
import { Link } from "react-router-dom";
import Icon from "../../components/Icon";
import { supabase } from "../../supabaseClient";

export const CustomSelect = ({ options, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    onSelect(option); // Propagate the selected option to the parent component
    setIsOpen(false); // Close the dropdown
  };

  return (
    <div className="">
      <button
        className="flex justify-between items-center w-full"
        onClick={handleToggleDropdown}
      >
        {/* {selectedOption ? selectedOption.label : "Select an option"} */}
        <Icon icon="heroicons:globe-alt" className="h-12 w-12 text-white" />
      </button>
      {isOpen && (
        <ul className="rounded-[10px] absolute right-[50px] w-64 list-none m-0 p-0 bg-[#130826] z-10">
          {options.map((option) => (
            <li
              key={option.value}
              onClick={() => handleOptionClick(option.value)}
              className="px-4 py-2 text-white cursor-pointer"
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

dayjs.extend(utc);
dayjs.extend(timezone);

const Home = ({
  token,
  eventData,
  genres,
  eventsLoading,
  setEventsLoading,
  language,
  setLanguage,
}) => {
  const [onlineEvents, setOnlineEvents] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [tabLoading, setTabLoading] = useState(false);

  const [filters, setFilters] = useState({
    datum: "Alle Datums",
    categorie: "Alle events",
    genre: [...genres],
    leeftijd: "Alle leeftijden",
    city: localStorage.getItem("city") || "Amsterdam",
  });

  const [currentCity, setCurrentCity] = useState(
    localStorage.getItem("city") || "Amsterdam"
  );

  const [cities, setCities] = useState([]);

  const [currentNetherlandsTime, setCurrentNetherlandsTime] = useState(() => {
    const netherlandsTime = new Date().toLocaleString("en-US", {
      timeZone: "Europe/Amsterdam",
    });
    return netherlandsTime;
  });
  const formattedCurrentDate = dayjs(currentNetherlandsTime).format(
    "YYYY-MM-DD HH:mm:ss"
  );

  useEffect(() => {
    supabase
      .from("cities")
      .select("*")
      .then(({ data, error }) => {
        if (error) {
          console.error(error);
        } else {
          setCities(data);
        }
      });

    const timer = setInterval(() => {
      setCurrentNetherlandsTime(() => {
        const netherlandsTime = new Date().toLocaleString("en-US", {
          timeZone: "Europe/Amsterdam",
        });
        return netherlandsTime;
      }); // Update current date and time
    }, 1000); // Update every second (adjust as needed)

    return () => {
      clearInterval(timer); // Clean up the timer when the component unmounts
    };
  }, []);

  useEffect(() => {
    filterAll();
    // setFilteredData(eventData);
    if (eventData.length > 0) {
      setEventsLoading(false);
    }
  }, [eventData, filters, currentCity]);

  const filterAll = () => {
    const filteredByDates = filterByDate(eventData);
    const filteredByCategory = filterByCategory(filteredByDates);
    const filteredByGenres = filterByGenre(filteredByCategory);
    const filteredByAge = filterByAge(filteredByGenres);
    const filteredByCity = filterByCity(filteredByAge);
    const filteredByPublished = filteredByCity.filter((event) => event.datetime > formattedCurrentDate);
    setFilteredData(filteredByPublished);
    setOnlineEvents(
      filteredByCity.filter(
        (event) =>
          formattedCurrentDate < adjustTime(event.signup_from) &&
          event.city === currentCity
      )
    );
  };

  const filterByDate = (arr) => {
    let filteredByDates = [];

    const filterStatus = filters["datum"].toLocaleLowerCase();
    const today = dayjs().utc().locale(language);
    const tomorrow = today.add(1, "day");
    const startOfWeek = today.startOf("week");
    const endOfWeek = today.endOf("week");
    const startOfNextWeek = today.add(1, "week").startOf("week");
    const endOfNextWeek = today.add(1, "week").endOf("week");
    const startOfMonth = today.startOf("month");
    const endOfMonth = today.endOf("month");

    switch (filterStatus) {
      case "vandaag": {
        filteredByDates = arr.filter((event) => {
          const eventDate = dayjs.unix(event.datetime).utc();
          return (
            eventDate.isSame(today, "day") &&
            eventDate.isSame(today, "month") &&
            eventDate.isSame(today, "year")
          );
        });
        break;
      }
      case "today": {
        filteredByDates = arr.filter((event) => {
          const eventDate = dayjs.unix(event.datetime).utc();
          return (
            eventDate.isSame(today, "day") &&
            eventDate.isSame(today, "month") &&
            eventDate.isSame(today, "year")
          );
        });
        break;
      }
      case "morgen": {
        filteredByDates = arr.filter((event) => {
          const eventDate = dayjs.unix(event.datetime).utc();
          return (
            eventDate.isSame(tomorrow, "day") &&
            eventDate.isSame(tomorrow, "month") &&
            eventDate.isSame(tomorrow, "year")
          );
        });
        break;
      }
      case "tomorrow": {
        filteredByDates = arr.filter((event) => {
          const eventDate = dayjs.unix(event.datetime).utc();
          return (
            eventDate.isSame(tomorrow, "day") &&
            eventDate.isSame(tomorrow, "month") &&
            eventDate.isSame(tomorrow, "year")
          );
        });
        break;
      }
      case "deze week": {
        filteredByDates = arr.filter((event) => {
          const eventDate = dayjs.unix(event.datetime).utc();
          return (
            (eventDate.isAfter(startOfWeek) && eventDate.isBefore(endOfWeek)) ||
            eventDate.isSame(startOfWeek) ||
            eventDate.isSame(endOfWeek)
          );
        });
        break;
      }
      case "this week": {
        filteredByDates = arr.filter((event) => {
          const eventDate = dayjs.unix(event.datetime).utc();
          return (
            (eventDate.isAfter(startOfWeek) && eventDate.isBefore(endOfWeek)) ||
            eventDate.isSame(startOfWeek) ||
            eventDate.isSame(endOfWeek)
          );
        });
        break;
      }
      case "volgende week": {
        filteredByDates = arr.filter((event) => {
          const eventDate = dayjs.unix(event.datetime).utc();
          return (
            (eventDate.isAfter(startOfNextWeek) &&
              eventDate.isBefore(endOfNextWeek)) ||
            eventDate.isSame(startOfNextWeek) ||
            eventDate.isSame(endOfNextWeek)
          );
        });
        break;
      }
      case "next week": {
        filteredByDates = arr.filter((event) => {
          const eventDate = dayjs.unix(event.datetime).utc();
          return (
            (eventDate.isAfter(startOfNextWeek) &&
              eventDate.isBefore(endOfNextWeek)) ||
            eventDate.isSame(startOfNextWeek) ||
            eventDate.isSame(endOfNextWeek)
          );
        });
        break;
      }
      case "deze maand": {
        filteredByDates = arr.filter((event) => {
          const eventDate = dayjs.unix(event.datetime).utc();
          return (
            (eventDate.isAfter(startOfMonth) &&
              eventDate.isBefore(endOfMonth)) ||
            eventDate.isSame(startOfMonth) ||
            eventDate.isSame(endOfMonth)
          );
        });
        break;
      }
      case "this month": {
        filteredByDates = arr.filter((event) => {
          const eventDate = dayjs.unix(event.datetime).utc();
          return (
            (eventDate.isAfter(startOfMonth) &&
              eventDate.isBefore(endOfMonth)) ||
            eventDate.isSame(startOfMonth) ||
            eventDate.isSame(endOfMonth)
          );
        });
        break;
      }
      default:
        filteredByDates = arr;
        break;
    }
    return filteredByDates;
  };

  const filterByCategory = (arr) => {
    let filteredByCategory = [];
    const filterStatus = filters["categorie"].toLowerCase();
    switch (filterStatus) {
      case "dag event":
        filteredByCategory = arr.filter(
          (event) => event.event_type.toLowerCase() === "dag event"
        );
        break;
      case "nacht event":
        filteredByCategory = arr.filter(
          (event) => event.event_type.toLowerCase() === "nacht event"
        );
        break;
      case "festival":
        filteredByCategory = arr.filter(
          (event) => event.event_type.toLowerCase() === "festival"
        );
        break;
      default:
        filteredByCategory = arr;
        break;
    }

    return filteredByCategory;
  };

  const filterByGenre = (arr) => {
    let filteredByGenres = [];
    const filterStatus = filters["genre"]?.map((genre) => genre.toLowerCase()); // array

    if (!filterStatus || filterStatus.length === 0) {
      return [];
    } else if (filterStatus.length === 0) {
      return [];
    }

    filteredByGenres = arr.filter((event) => {
      const eventGenres = event.genres
        .split(",")
        .map((genre) => genre.trim().toLowerCase());
      return eventGenres.some((selectedGenre) =>
        filterStatus.includes(selectedGenre.toLowerCase())
      );
    });

    return filteredByGenres;
  };

  const filterByAge = (arr) => {
    let filteredByAge = [];
    const filterStatus = filters["leeftijd"];

    switch (filterStatus) {
      case "18+":
        filteredByAge = arr;
        break;
      case "21+":
        filteredByAge = arr.filter((event) => +event.minimum_age >= 21);
        break;
      case "23+":
        filteredByAge = arr.filter((event) => +event.minimum_age >= 23);
        break;
      default:
        filteredByAge = arr;
        break;
    }
    return filteredByAge;
  };

  const filterByCity = (arr) => {
    let filteredByCity = [];
    const filterStatus = filters["city"];

    filteredByCity = arr.filter((event) => event.city.includes(filterStatus));

    return filteredByCity;
  };

  const changeCity = (city) => {
    localStorage.setItem("city", city);
    setTabLoading(true);
    setFilters({
      datum: "Alle Datums",
      categorie: "Alle events",
      genre: [...genres],
      leeftijd: "Alle leeftijden",
      city: city,
    });
    setCurrentCity(city);
    filterAll();
    // will remove preloader when data is filtered

    setTimeout(() => {
      setTabLoading(false);
      //   setEventsLoading(false);
    }, 200);
  };

  const { t } = useTranslation();

  const showOnlineEventsSection = onlineEvents.length > 0;

  return (
    <div className="bg-[#12041f]">
      <div className="hero-section relative">
        <div className="lg:hidden w-full flex items-center justify-center pt-12">
          <div className="flex justify-between w-[90%] items-center">
            <Link to="https://linktr.ee/22nightmember" target="_blank">
              <img
                src={WhatsappLogo}
                className="w-12 h-12"
                alt="Whatsapp logo"
              />
            </Link>
            <Link to="/">
              <Logo />
            </Link>
            <CustomSelect
              options={[
                { value: "nl", label: "🇳🇱 Nederlands" },
                { value: "en", label: "🇺🇸 English" },
              ]}
              onSelect={(value) => {
                setLanguage(value);
              }}
            />
          </div>
        </div>
        <div className="pt-[40px] lg:pt-[80px] lg:pb-[40px] px-[10px] lg:px-[100px]">
          <div className="hero-card rounded-[16px] border-2 p-[10px] lg:p-[40px]">
            <div className="hero-tabs">
              <div
                className={`flex w-full items-center justify-center text-center lg:px-[12%] ${"block  tab-active"}`}
                data-id="tab1"
              >
                <h1
                  className="2xl:px-24 w-full max-lg:pb-[24px] text-[40px] lg:!text-[64px] text-white leading-[44px] lg:leading-[72px]"
                  id="heading"
                >
                  {t("guestlist")} <br />
                  {currentCity}
                </h1>
              </div>
            </div>

            <div className="hero-search-bar mt-[64px] lg:mt-[96px] rounded-[16px]">
              <SearchBar
                eventData={eventData}
                formattedCurrentDate={formattedCurrentDate}
              />
            </div>
          </div>
        </div>
      </div>
      {/* Ontdek envenment */}
      <section>
        <DiscoverEvent
          filters={filters}
          setFilters={setFilters}
          token={token}
          city={currentCity}
          genres={genres}
          changeCity={changeCity}
        />
      </section>

      {/*  */}

      <section className="zoek-evenementen !pb-[80px]">
        {eventsLoading || tabLoading ? (
          <div className="custom-container">
            <EventItemPreloader />

            {/* {isDivider && ( */}
            <div className="card-divider"></div>
            {/* )} */}
          </div>
        ) : filteredData.length > 0 ? (
          <>
            <EventsList
              eventsData={filteredData}
              currentCity={currentCity}
              isDivider={showOnlineEventsSection}
              formattedCurrentDate={formattedCurrentDate}
              language={language}
            />
          </>
        ) : (
          <>
            <NoEvents />
          </>
        )}

        {/* <NoEvents /> */}
      </section>

      {showOnlineEventsSection && (
        <>
          {/* <div className="card-divider"></div> */}
          <section>
            <div className="custom-container">
              <h2 className="binnenkort">{t("coming_soon")}</h2>
              <p className="text-white text-p">{t("want_to_be_reminded")}</p>
            </div>
          </section>
        </>
      )}
      {showOnlineEventsSection && (
        <section className="pb-[80px]">
          <Carousel
            onlineEvents={onlineEvents}
            city={currentCity}
            formattedCurrentDate={formattedCurrentDate}
          />
        </section>
      )}
    </div>
  );
};
export default Home;
