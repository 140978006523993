import { Link, useNavigate } from "react-router-dom";
import { LogoSvg } from "../utils/svg";
import ArrowImg from "../assets/image svg/arrow-left.svg";
import popupImg from "../assets/image svg/Illustration.svg";
import "./thanks/thanksPage.css";
import { useTranslation } from "react-i18next";

const ThanksDealPage = () => {
  const navigate = useNavigate();

  const handleBackClick = (e) => {
    e.preventDefault();
    navigate(`/`);
  };

  const ticket_link = window.location.href.split("=")[1];

  const { t } = useTranslation();

  const msgHeader = t("here_is_your_community_deal");
  const msgText = t("email_with_confirmation");
  const msgBtn = t("back_to_home");

  return (
    <div>
      <section class="hero-section-single thanksPage">
        <div class="container-single">
          <div className="row">
            <div className="arrow">
              <a href="/" onClick={handleBackClick}>
                <img src={ArrowImg} alt="arrow" className="logo" />
              </a>
            </div>
            <div className="logo-Img">
              <a href="/">
                <LogoSvg />
              </a>
            </div>
          </div>
        </div>
        <div class="popup-content-box">
          <div class="popup-content">
            <img src={popupImg} class="Illustration" />
            <p class="Hier">{msgHeader}</p>
            <p class="Je">{msgText}</p>
            <p className="Je">
              {t('if_you_have_not_been_redirected')},{" "}
              <span className="text-purple-400 font-semibold">
                <Link to={ticket_link} target="_blank" className="cursor-pointer active:text-purple-400 hover:text-purple-500">
                  {t('click_here')}.
                </Link>
              </span>
            </p>
            <a class="Terug" onClick={handleBackClick}>
              {msgBtn}
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ThanksDealPage;
