import React, { useEffect, useState } from "react";
import { supabase } from "../supabaseClient";
import { v4 as uuidv4 } from "uuid";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";
import DatePicker from "react-datepicker";
import { availableGenres, convertToUTC, getPublicImageUrl } from "../helpers/helpers";

const defaultFormData = {
  name: "",
  description: "",
  artists: "",
  datetime: "",
  signup_from: "",
  signup_until: "",
  minimum_age: 18,
  category: "",
  ticket_link: "",
  sold_out: false,
  event_type: "",
  city: "",
  trending: false,
  original_price: 0,
  our_price: 0,
  coupon_code: "",
  venue_id: "",
  event_image_filename: "",
  genres: [],
  exclusive_event: false,
};

export default function CreateEventModal({ show, setShow, setEvents }) {
  const [formData, setFormData] = useState(defaultFormData);

  const [error, setError] = useState([]);

  const [venues, setVenues] = useState([]);
  const [cities, setCities] = useState([]);
  useEffect(() => {
    supabase
      .from("Venue")
      .select("*")
      .then((res) => {
        setVenues(res.data);
      });

    supabase
      .from("City")
      .select("*")
      .then((res) => {
        setCities(res.data);
      });
  }, []);

  const saveEvent = () => {
    const optionalFields = [
      "coupon_code",
      "artists",
      "sold_out",
      "trending",
      "exclusive_event",
    ];

    // Guestlists dont have a ticket link
    if (formData.category === "Guestlist") {
      optionalFields.push("ticket_link");
    }

    // Error handling
    let hasError = false;
    Object.values(formData).forEach((item, index) => {
      if (
        item === "" &&
        !optionalFields.includes(Object.keys(formData)[index])
      ) {
        setError((prev) => {
          return [...prev, Object.keys(formData)[index]];
        });
        hasError = true;
      } else {
        setError((prev) => {
          return prev.filter((error) => error !== Object.keys(formData)[index]);
        });
      }
    });

    // If the our price or original price is not a number
    if (isNaN(formData.our_price)) {
      setError((prev) => {
        return [...prev, "our_price"];
      });
      return;
    }

    if (isNaN(formData.original_price)) {
      setError((prev) => {
        return [...prev, "original_price"];
      });
      return;
    }

    if (hasError) {
      return;
    }

    delete formData["venue"];

      // Convert the time to UTC before sending to the server
      const utcDateTime = convertToUTC(formData.datetime);
      const utcSignupFrom = convertToUTC(formData.signup_from);
      const utcSignupUntil = convertToUTC(formData.signup_until);

      // Create a new object with the UTC datetime
      const formDataToSave = {
        ...formData,
        datetime: utcDateTime,
        signup_from: utcSignupFrom,
        signup_until: utcSignupUntil,
      };


    delete formData.id;
    supabase
      .from("Events")
      .insert({ ...formDataToSave, genres: formData.genres.join(", ") })
      .then((response) => {
        setShow(false);
        setEvents((prev) => {
          return [{ ...formData, genres: formData.genres.join(", ") }, ...prev];
        });
        setFormData(defaultFormData);
      });
  };

  const validateAndUploadImage = (e) => {
    const file = e.target.files[0];

    if (file) {
      const img = new Image();
      const objectUrl = URL.createObjectURL(file);

      img.onload = () => {
        if (img.width === 975 && img.height === 525) {
          // Proceed with upload if the dimensions are correct
          uploadImage(file);
        } else {
          setError((prev) => {
            return [...prev, "event_image_filename"];
          });
          alert(
            `Image must be 975px by 525px! The image you uploaded is ${img.width}px by ${img.height}px.`
          );
        }

        URL.revokeObjectURL(objectUrl); // Clean up the object URL
      };

      img.src = objectUrl;
    }
  };

  const uploadImage = (file) => {
    const uuid = uuidv4();

    const asset_name = `${uuid}-${file.name}`;

    supabase.storage.from("images").upload(asset_name, file, { upsert: true });

    setFormData({ ...formData, event_image_filename: asset_name });
  };

  const addOrRemoveGenre = (genre) => {
    if (formData.genres.includes(genre)) {
      const genreCopy = [...formData.genres];

      const i = formData.genres.indexOf(genre);
      genreCopy.splice(i, 1);
      // Remove genre
      setFormData({ ...formData, genres: genreCopy });
    } else {
      // Add genre
      setFormData({ ...formData, genres: [...formData.genres, genre] });
    }
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto "
        onClose={() => {}}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="w-full h-screen bg-[#00000040] fixed z-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-7xl sm:w-full sm:p-6">
              <div className="absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-12 w-12" aria-hidden="true" />
                </button>
              </div>
              <div className="mb-3 flex justify-center w-full">
                <div className="flex-col flex items-center w-full">
                  <div className="text-center w-full">
                    <h1 className="text-5xl font-bold text-black w-full">
                      Create a new event
                    </h1>
                  </div>
                </div>
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">Event name</label>
                <input
                  placeholder="Event name"
                  value={formData.name}
                  type="text"
                  className={`text-2xl text-black bg-white border-2 border-gray-300 p-4 rounded-lg ${
                    error.includes("name") ? "border-red-500" : ""
                  }`}
                  onChange={(e) => {
                    setFormData({ ...formData, name: e.target.value });
                  }}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">
                  Event description
                </label>
                <textarea
                  value={formData.description}
                  placeholder="Event description"
                  rows="5"
                  className={`text-2xl text-black bg-white border-2 border-gray-300 p-4 rounded-lg ${
                    error.includes("description") ? "border-red-500" : ""
                  }`}
                  onChange={(e) => {
                    setFormData({ ...formData, description: e.target.value });
                  }}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">
                  Artists (comma separated)
                </label>
                <textarea
                  value={formData.artists}
                  placeholder="Hardwell, Martin Garrix, Tiesto ..."
                  rows="5"
                  className={`text-2xl text-black bg-white border-2 border-gray-300 p-4 rounded-lg ${
                    error.includes("artists") ? "border-red-500" : ""
                  }`}
                  onChange={(e) => {
                    setFormData({ ...formData, artists: e.target.value });
                  }}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">City</label>
                <select
                  className={`text-3xl p-4 rounded-lg border-2 ${
                    error.includes("city")
                      ? "border-red-500"
                      : "border-gray-300"
                  }`}
                  value={formData.city}
                  onChange={(e) => {
                    setFormData({ ...formData, city: e.target.value });
                  }}
                >
                  <option value="">Choose a city</option>
                  {cities?.map((city) => {
                    return <option value={city.name}>{city.name}</option>;
                  })}
                </select>
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">Date & time</label>
                <DatePicker
                  selected={formData.datetime && new Date(formData.datetime)}
                  onChange={(date) => {
                    setFormData({ ...formData, datetime: date });
                  }}
                  showTimeSelect
                  dateFormat="d MMMM YYYY HH:mm"
                  className={`text-2xl text-black bg-white border-2 ${
                    error.includes("datetime")
                      ? "border-red-500"
                      : "border-gray-300"
                  } p-4 rounded-lg`}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">Signup from</label>
                <DatePicker
                  selected={
                    formData.signup_from && new Date(formData.signup_from)
                  }
                  onChange={(date) => {
                    setFormData({ ...formData, signup_from: date });
                  }}
                  showTimeSelect
                  dateFormat="d MMMM YYYY HH:mm"
                  className={`text-2xl text-black bg-white border-2 ${
                    error.includes("signup_from")
                      ? "border-red-500"
                      : "border-gray-300"
                  } p-4 rounded-lg`}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">Signup until</label>
                <DatePicker
                  selected={
                    formData.signup_until && new Date(formData.signup_until)
                  }
                  onChange={(date) => {
                    setFormData({ ...formData, signup_until: date });
                  }}
                  showTimeSelect
                  dateFormat="d MMMM YYYY HH:mm"
                  className={`text-2xl text-black bg-white border-2 ${
                    error.includes("signup_until")
                      ? "border-red-500"
                      : "border-gray-300"
                  } p-4 rounded-lg`}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">Minimum age</label>
                <input
                  type="number"
                  className={`text-3xl p-4 rounded-lg border-2 ${
                    error.includes("minimum_age")
                      ? "border-red-500"
                      : "border-gray-300"
                  }`}
                  value={formData.minimum_age}
                  onChange={(e) => {
                    setFormData({ ...formData, minimum_age: e.target.value });
                  }}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">Event type</label>
                <select
                  className={`text-3xl p-4 rounded-lg border-2 ${
                    error.includes("event_type")
                      ? "border-red-500"
                      : "border-gray-300"
                  }`}
                  value={formData.event_type}
                  onChange={(e) => {
                    setFormData({ ...formData, event_type: e.target.value });
                  }}
                >
                  <option value="">Choose event type</option>
                  <option value="Nacht event">Nacht event</option>
                  <option value="Dag event">Dag event</option>
                  <option value="Festival">Festival</option>
                </select>
              </div>
              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">Category</label>
                <select
                  className={`text-3xl p-4 rounded-lg border-2 ${
                    error.includes("category")
                      ? "border-red-500"
                      : "border-gray-300"
                  }`}
                  value={formData.category}
                  onChange={(e) => {
                    setFormData({ ...formData, category: e.target.value });
                  }}
                >
                  <option value="">Choose event category</option>
                  <option value="Community ticket">Community ticket</option>
                  <option value="Guestlist">Guestlist</option>
                </select>
              </div>

              {formData.category === "Community ticket" && (
                <div className="mt-6 flex flex-col justify-start gap-1">
                  <label className="text-xl text-black mb-0">Ticket link</label>
                  <input
                    placeholder="Ticket link"
                    value={formData.ticket_link}
                    type="text"
                    className={`text-2xl text-black bg-white border-2 ${
                      error.includes("ticket_link")
                        ? "border-red-500"
                        : "border-gray-300"
                    } p-4 rounded-lg`}
                    onChange={(e) => {
                      setFormData({ ...formData, ticket_link: e.target.value });
                    }}
                  />
                </div>
              )}

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">Trending</label>
                <input
                  checked={formData.trending}
                  type="checkbox"
                  className={`text-2xl w-8 h-8 text-black bg-white border-2 ${
                    error.includes("trending")
                      ? "border-red-500"
                      : "border-gray-300"
                  } p-4 rounded-lg`}
                  onChange={(e) => {
                    setFormData({ ...formData, trending: e.target.checked });
                  }}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">Sold out</label>
                <input
                  checked={formData.sold_out}
                  type="checkbox"
                  className={`text-2xl w-8 h-8 text-black bg-white border-2 ${
                    error.includes("sold_out")
                      ? "border-red-500"
                      : "border-gray-300"
                  } p-4 rounded-lg`}
                  onChange={(e) => {
                    setFormData({ ...formData, sold_out: e.target.checked });
                  }}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">
                  Original price
                </label>
                <input
                  value={formData.original_price}
                  type="text"
                  placeholder="50"
                  className={`text-2xl text-black bg-white border-2 ${
                    error.includes("original_price")
                      ? "border-red-500"
                      : "border-gray-300"
                  } p-4 rounded-lg`}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      original_price: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">Our price</label>
                <input
                  value={formData.our_price}
                  type="text"
                  placeholder="22,50"
                  className={`text-2xl text-black bg-white border-2 ${
                    error.includes("our_price")
                      ? "border-red-500"
                      : "border-gray-300"
                  } p-4 rounded-lg`}
                  onChange={(e) => {
                    setFormData({ ...formData, our_price: e.target.value });
                  }}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">
                  Coupon code (optional)
                </label>
                <input
                  value={formData.coupon_code}
                  type="text"
                  className={`text-2xl text-black bg-white border-2 ${
                    error.includes("coupon_code")
                      ? "border-red-500"
                      : "border-gray-300"
                  } p-4 rounded-lg`}
                  onChange={(e) => {
                    setFormData({ ...formData, coupon_code: e.target.value });
                  }}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">Venue</label>
                <select
                  className={`text-3xl p-4 rounded-lg border-2 ${
                    error.includes("venue_id")
                      ? "border-red-500"
                      : "border-gray-300"
                  }`}
                  value={formData.venue_id}
                  onChange={(e) => {
                    setFormData({ ...formData, venue_id: e.target.value });
                  }}
                >
                  <option value="">Choose a venue</option>
                  {venues.map((venue) => {
                    return <option value={venue.id}>{venue.name}</option>;
                  })}
                </select>
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">
                  Exclusive event
                </label>
                <input
                  checked={formData.exclusive_event}
                  type="checkbox"
                  className={`text-2xl w-8 h-8 text-black bg-white border-2 ${
                    error.includes("exclusive_event")
                      ? "border-red-500"
                      : "border-gray-300"
                  } p-4 rounded-lg`}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      exclusive_event: e.target.checked,
                    });
                  }}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">Genres</label>
                <div className="flex flex-wrap gap-2">
                  {availableGenres.map((genre) => {
                    return (
                      <button
                        onClick={() => {
                          addOrRemoveGenre(genre);
                        }}
                        className={`${
                          formData.genres.includes(genre)
                            ? "bg-green-300 hover:bg-red-400"
                            : "bg-gray-100 hover:bg-green-200"
                        } transition-all text-black text-2xl p-2`}
                      >
                        {genre}
                      </button>
                    );
                  })}
                </div>
              </div>

              {/* Genre */}

              {/* Event image */}
              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">Event image</label>
                <input
                  type="file"
                  accept=".jpeg, .jpg, .png"
                  className={`text-2xl text-black bg-white border-2 ${
                    error.includes("event_image_filename")
                      ? "border-red-500"
                      : "border-gray-300"
                  } p-4 rounded-lg`}
                  onChange={(e) => {
                    validateAndUploadImage(e);
                  }}
                />
                {formData.event_image_filename && (
                  <>
                    <img
                      src={getPublicImageUrl(formData.event_image_filename)}
                      className="w-[200px] h-auto"
                      alt={""}
                    />
                  </>
                )}
              </div>

              {error.length > 0 && (
                <p className="text-3xl font-semibold mt-5 text-red-500">
                  Please fill in all fields correctly.
                </p>
              )}

              <div className="flex items-center justify-end w-full pt-9">
                <button
                  onClick={() => {
                    saveEvent();
                  }}
                  className="text-white transition-all px-6 lg:px-12 py-3 rounded-lg text-3xl font-semibold hover:bg-[#38c28f] bg-[#07af71]"
                >
                  Save event
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
