import React from 'react';
import { Icon as Icons } from '@iconify/react';
const Icon = ({
  icon,
  className,
  width,
  rotate,
  hFlip,
  vFlip,
}) => {
  return (
    <>
      <Icons
        width={width}
        rotate={rotate}
        hFlip={hFlip}
        icon={icon}
        className={className}
        vFlip={vFlip}
      />
    </>
  );
};

export default Icon;
