import { handleType } from '../slices/themeSlice';
import { useDispatch, useSelector } from "react-redux";

const useMenuLayout = () => {
  const dispatch = useDispatch();
  const menuType = useSelector(state => state.theme.type);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setMenuLayout = (value) => {
    dispatch(handleType(value));
  };

  return [menuType, setMenuLayout];
};

export default useMenuLayout;
