import React, { useEffect, useState } from "react";
import { Table } from "flowbite-react";
import Icon from "../components/Icon";
import { supabase } from "../supabaseClient";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import CreateEventModal from "../components/CreateEventModal";

export default function ManageEvents() {
  const [events, setEvents] = useState([]);
  const [archivedEvents, setArchivedEvents] = useState([]);

  const [showCreateEventModal, setshowCreateEventModal] = useState(false);

  useEffect(() => {
    supabase
      .from("Events")
      .select("*, venue:Venue (*), attendees:EventSignUp (*)")
      .gte("datetime", new Date().toISOString())
      .order("datetime", { ascending: true })
      .then((res) => {
        setEvents(res.data);
      });
    supabase
      .from("Events")
      .select("*, venue:Venue (*), attendees:EventSignUp (*)")
      .lte("datetime", new Date().toISOString())
      .gte("datetime", dayjs().subtract(60, "days").toISOString())
      .order("datetime", { ascending: true })
      .then((res) => {
        setArchivedEvents(res.data);
      });
  }, []);

  const duplicateEvent = async (eventId) => {
    try {
      // Fetch the event to duplicate
      const { data: eventToDuplicate, error: fetchError } = await supabase
        .from("Events")
        .select("*")
        .eq("id", eventId)
        .single();

      if (fetchError) throw fetchError;

      // Create a new event object with the same properties
      const newEvent = {
        ...eventToDuplicate,
        id: undefined,
        name: `${eventToDuplicate.name} (Copy)`,
        datetime: dayjs(new Date().toISOString()).add(1, "day").toISOString(), // Set date to tomorrow
        created_at: new Date().toISOString(),
      };

      // Insert the new event
      await supabase
        .from("Events")
        .insert(newEvent)
        .single();

      // Fetch the newly inserted event
      const { data: insertedEvent, error: fetchNewEventError } = await supabase
        .from("Events")
        .select("*, venue:Venue (*), attendees:EventSignUp (*)")
        .eq("name", newEvent.name)
        .single();

      if (fetchNewEventError) throw fetchNewEventError;

      // Add the new event to the events state
      setEvents((prevEvents) => [insertedEvent, ...prevEvents]);

      // Update the events state
      // setEvents((prevEvents) => [...prevEvents, insertedEvent]);

      console.log("Event duplicated successfully");
    } catch (error) {
      console.error("Error duplicating event:", error.message);
    }
  };

  const deleteEvent = async (eventId) => {
    try {
      // Show confirmation dialog
      const isConfirmed = window.confirm("Are you sure you want to delete this event?");

      if (!isConfirmed) {
        return; // User cancelled the deletion
      }

      // Delete the event from the database
      const { error } = await supabase
        .from("Events")
        .delete()
        .eq("id", eventId);

      if (error) throw error;

      // Remove the event from the local state
      setEvents((prevEvents) => prevEvents.filter((event) => event.id !== eventId));
      setArchivedEvents((prevEvents) => prevEvents.filter((event) => event.id !== eventId));

      console.log("Event deleted successfully");
    } catch (error) {
      console.error("Error deleting event:", error.message);
    }
  };

  const formatDateTime = (datetime) => {
    return dayjs(datetime).local("nl").format("D MMMM");
  };

  const navigate = useNavigate();

  // published or archive
  const [activeTab, setActiveTab] = useState("published");

  return (
    <div className="w-full text-white text-3xl mt-12 overflow-x-auto p-5">
      <CreateEventModal
        show={showCreateEventModal}
        setShow={(show) => {
          setshowCreateEventModal(show);
        }}
        create={showCreateEventModal}
        setEvents={setEvents}
      />
      <div className="flex justify-between items-center w-full">
        <h1 className="text-white !text-5xl font-semibold !text-start w-fit">
          Manage events
        </h1>
        <button
          onClick={() => {
            setshowCreateEventModal(true);
          }}
          className="bg-white py-5 px-12 rounded-lg shadow-md font-semibold text-black !text-[20px] flex items-center justify-center gap-2"
        >
          <Icon icon="heroicons:plus" className="h-9 w-9" />
        </button>
      </div>
      <div className="flex justify-start items-center gap-3 mt-12 text-[18px]">
        <button
          onClick={() => setActiveTab("published")}
          className={`border-b-2 p-3 text-white ${
            activeTab === "published" ? "border-white" : "border-transparent"
          }`}
        >
          Published ({events.length})
        </button>
        <button
          onClick={() => setActiveTab("archive")}
          className={`border-b-2 p-3 text-white ${
            activeTab === "archive" ? "border-white" : "border-transparent"
          }`}
        >
          Archive ({archivedEvents.length})
        </button>
      </div>
      <Table className="mt-8 mb-[100px]">
        <Table.Head>
          <Table.HeadCell className="text-3xl w-[30%] capitalize">Titel</Table.HeadCell>
          <Table.HeadCell className="text-3xl w-[10%] capitalize">
            Categorie
          </Table.HeadCell>
          <Table.HeadCell className="text-3xl w-[20%] capitalize">Event</Table.HeadCell>
          <Table.HeadCell className="text-3xl w-[10%] capitalize">Dag</Table.HeadCell>
          <Table.HeadCell className="text-3xl w-[20%] capitalize">
            Gepubliceerd
          </Table.HeadCell>
          <Table.HeadCell className="text-3xl w-[5%] capitalize">
            Aanmeldingen
          </Table.HeadCell>
          <Table.HeadCell className="text-3xl w-[5%] capitalize">
            Actions
          </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {(activeTab === "published" ? events : archivedEvents).map(
            (event, index) => {
              return (
                <Table.Row
                  onClick={() => {
                    navigate(`/admin/event/${event.id}`);
                  }}
                  className={`cursor-pointer hover:bg-blue-100 transition-all ${
                    index % 2 === 1 ? "bg-neutral-100" : "bg-white"
                  }`}
                >
                  <Table.Cell className="w-[30%] p-16 text-2xl whitespace-nowrap font-medium text-gray-900 dark:text-white">
                    {event.name}
                  </Table.Cell>
                  <Table.Cell className="text-2xl w-[10%] ">{event.category}</Table.Cell>
                  <Table.Cell className="text-2xl w-[20%]">
                    {formatDateTime(event.datetime)}
                  </Table.Cell>
                  <Table.Cell className="text-2xl w-[10%]">
                    {dayjs(event.datetime).format("dddd")}
                  </Table.Cell>
                  <Table.Cell className="text-2xl w-[20%]">
                    {dayjs(event.created_at).format("DD MMMM HH:mm")}
                  </Table.Cell>
                  <Table.Cell className="text-2xl w-[5%]">
                    {event.attendees?.length}
                  </Table.Cell>
                  <Table.Cell className="flex items-start flex-col justify-center gap-2 w-[5%]">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        duplicateEvent(event.id);
                      }}
                      className="p-2 hover:bg-neutral-300 rounded-lg transition-all"
                    >
                      <Icon
                        icon="heroicons:document-duplicate"
                        className="h-10 w-10"
                      />
                    </button>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteEvent(event.id);
                      }}
                      className="p-2 hover:bg-neutral-300 rounded-lg transition-all"
                    >
                      <Icon icon="heroicons:trash" className="h-10 w-10" />
                    </button>
                  </Table.Cell>
                </Table.Row>
              );
            }
          )}
        </Table.Body>
      </Table>
    </div>
  );
}
