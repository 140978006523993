import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../utils/axiosInstance";

const initialState = {
  loading: true,
  id: "",
  email: "",
  first_name: "",
  last_name: "",
  phone_number: "",
  date_of_birth: "",
  location: "",
  gender: "",
  token: "",
  created_at: "",
  role: "",
  genres: "",
};

/**
 * A thunk action creator that fetches user data from the server.
 * @returns {Promise<any>} - A promise that resolves to the fetched user data.
 */
export const fetchUser = createAsyncThunk("/user/fetchUser", async () => {
  const res = await axiosInstance.get("/api/v1/user/");

  if (res.status !== 200) {
    return initialState;
  }
  return res.data;
});

export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setUser: (state, action) => {
      state.loading = action.payload.loading;
      state.id = action.payload.id;
      state.email = action.payload.email;
      state.first_name = action.payload.first_name;
      state.last_name = action.payload.last_name;
      state.phone_number = action.payload.phone_number;
      state.date_of_birth = action.payload.date_of_birth;
      state.location = action.payload.location;
      state.gender = action.payload.gender;
      state.token = action.payload.token;
      state.created_at = action.payload.created_at;
      state.role = action.payload.role;
      state.genres = action.payload.genres;
    },
    changeToken: (state, action) => {
      state.token = action.payload;

      localStorage.setItem("token", action.payload);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        const user = action.payload.user;

        state.loading = user.loading;
        state.id = user.id;
        state.email = user.email;
        state.first_name = user.first_name;
        state.last_name = user.last_name;
        state.phone_number = user.phone_number;
        state.date_of_birth = user.date_of_birth;
        state.location = user.location;
        state.gender = user.gender;
        state.token = user.token;
        state.created_at = user.created_at;
        state.genres = user.genres;

        state.loading = false;
      })
      .addCase(fetchUser.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { setUser, changeToken } = userSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.user.value)`
export const selectUser = (state) => state.user;

export default userSlice.reducer;
