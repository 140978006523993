import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../slices/userReducer";
import { Link } from "react-router-dom";
import Logo from "../components/Logo";
import { CustomSelect } from "./home/Home";
import Icon from "../components/Icon";
import EventCard from "../components/EventCard";
import { supabase } from "../supabaseClient";

export default function ProfileEvents({ language, setLanguage }) {
  const user = useSelector(selectUser);

  const [previousEvents, setPreviousEvents] = useState([]);

  const [totalSavings, setTotalSavings] = useState(0);

  useEffect(() => {
    supabase
      .from("EventSignUp")
      .select("*, event:Events (*, venue:Venue (*)) ")
      .eq("user_id", user.id)
      .then((res) => {
        setPreviousEvents(
          res.data.map((signup) => {
            return signup.event;
          })
        );
        setTotalSavings(
          res.data
            .map((signup) => {
              return signup.event.original_price - signup.event.our_price;
            })
            .reduce((a, b) => a + b, 0)
        );
      });
  }, []);

  return (
    <div className="bg-[#12041f]">
      <div
        className="hero-section relative min-h-[100vh] "
        style={{ background: "#0A0315" }}
      >
        <div className="lg:hidden w-full flex items-center justify-center pt-12">
          <div className="flex justify-between w-[90%] items-center">
            <Link to="/profile">
              <Icon
                icon="heroicons:arrow-left"
                className="text-white h-12 w-12"
              />
            </Link>
            <Link to="/">
              <Logo />
            </Link>
            <CustomSelect
              options={[
                { value: "nl", label: "🇳🇱 Nederlands" },
                { value: "en", label: "🇺🇸 English" },
              ]}
              onSelect={(value) => {
                setLanguage(value);
              }}
            />
          </div>
        </div>
        <div className="mt-12 flex flex-col items-center">
          <div className="w-[90%]">
            <h2 className="text-[26px] text-white">Afgelopen events</h2>
            {previousEvents.length > 0 ? (
              <>
                <div className="mt-[16px] zoek-evenementen !p-0 m-0">
                  {previousEvents.map((event) => {
                    return (
                      <div className="mt-3 w-full">
                        <div className="event-box">
                          <div className="row">
                            <EventCard
                              key={event.id}
                              eventCardData={event}
                              singleEvent={true}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <hr className="mt-[20px] border-gray-700" />
                <div className="mt-[24px] flex items-center flex-col">
                  <h2 className="text-[22px] text-white">
                    Je hebt in totaal bespaard:
                  </h2>
                  <h1 className="text-[26px] font-semibold text-purple-300 leading-[30px]">
                    €{totalSavings.toFixed(2)}
                  </h1>
                </div>
              </>
            ) : (
              <div className="flex items-center flex-col justify-center mt-[40px] text-[18px] text-center text-white">
                Je hebt je nog niet aangemeld voor een event.
                <Link to="/" className="premium-button px-12 py-4 mt-2 rounded-[8px]">Events bekijken</Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
