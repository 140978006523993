import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LogoSvg } from "../../utils/svg";
import ArrowImg from "../../assets/image svg/arrow-left.svg";
import popupImg from "../../assets/image svg/Illustration.svg";
import "./thanksPage.css";
import { useTranslation } from "react-i18next";
import { supabase } from "../../supabaseClient";

const ThanksPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [event, setEvent] = useState();

  useEffect(() => {
    const getEvent = async () => {
      const { data, error } = await supabase
        .from("Events")
        .select("*")
        .eq("id", location.state)
        .single();

      setEvent(data);
    };

    getEvent();
  }, []);

  const handleBackClick = (e) => {
    e.preventDefault();
    navigate(`/`);
  };

  const [msgHeader, setMsgHeader] = useState("");

  useEffect(() => {
    if (event) {
      const eventCategory = event.category;

      setMsgHeader(
        eventCategory !== "Community ticket"
          ? t("you_on_the_list")
          : t("here_is_your_community_ticket")
      );
    }
  }, [event]);

  const { t } = useTranslation();

  const msgText = t("email_with_confirmation");
  const msgBtn = t("back_to_home");

  if (!event) {
    return (
      <div
        className="hero-section-single thanksPage"
        style={{ minHeight: "100dvh" }}
      ></div>
    );
  }

  return (
    <div>
      <section class="hero-section-single thanksPage min-h-screen">
        <div class="container-single">
          <div className="row">
            <div className="arrow">
              <a href="/" onClick={handleBackClick}>
                <img src={ArrowImg} alt="arrow" className="logo" />
              </a>
            </div>
            <div className="logo-Img">
              <a href="/">
                <LogoSvg />
              </a>
            </div>
          </div>
        </div>
        {event && (
          <div class="popup-content-box">
            <div class="popup-content">
              <img src={popupImg} class="Illustration" />
              <p class="Hier text-center">{msgHeader}</p>
              <p class="Je">{msgText}</p>
              <a class="Terug" onClick={handleBackClick}>
                {msgBtn}
              </a>
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default ThanksPage;
